import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import MapPortal from './components/map.js'
import NavBox from './components/nav.js'
import MapModals from './components/modal.js'
import SideNav from './components/sideNav.js'
import moment from 'moment'
import { truncate } from '@turf/turf';
//import { Http2ServerRequest } from 'http2';

const http = require('http')
const https = require('https')
const fetch = require('node-fetch')

// Tile URLs
const DigitalElevation = 'http://home.kieandmike.com:1001/height/{z}/{x}/{y}.png';
//const DigitalElevation = '';
//const SurfaceMap = 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png';
//const Lights2015 = 'http://192.168.2.2:3009/lights/2016/{z}/{x}/{y}.png';
//const Lights2016 = 'http://192.168.2.2:3009/lights/2015/{z}/{x}/{y}.png';

//const DigitalElevation = 'http://home.kieandmike.com:1001/height/{z}/{x}/{y}.png';
//const SurfaceMap = 'http://c.tile.stamen.com/toner/{z}/{x}/{y}.png';
//const SurfaceMap = 'https://stamen-tiles-c.a.ssl.fastly.net/toner/{z}/{x}/{y}.png'
const SurfaceMap = 'https://tiles.stadiamaps.com/tiles/stamen_toner_background/{z}/{x}/{y}.png';
//const SurfaceMap = 'http://home.kieandmike.com:1001/height/{z}/{x}/{y}.png';

const Lights2015 = 'http://home.kieandmike.com:1001/lights/2016/{z}/{x}/{y}.png';
const Lights2016 = 'http://home.kieandmike.com:1001/lights/2015/{z}/{x}/{y}.png';
//const Lights2015 = 'http://home.kieandmike.com:1001/jan/tiles/{z}/{x}/{y}.png';
//const Lights2016 = 'http://home.kieandmike.com:1001/jan/tiles/{z}/{x}/{y}.png';
//const Pollution = 'http://home.kieandmike.com:1001/april_pol/tiles/{z}/{x}/{y}.png';
const Pollution = 'http://home.kieandmike.com:1001/before_no2/{z}/{x}/{y}.png';

// Pollution Tiles
//const pollutionBase = 'http://satellite.globalmonitor.ch/'
//const pollutionBase = 'http://home.kieandmike.com:1001/tiles/'
const pollutionBase = './'
const jan01 = 'http://home.kieandmike.com:1001/tiles/2020-01-02/{z}/{x}/{y}.png';
const jan15 = 'http://home.kieandmike.com:1001/jan15/{z}/{x}/{y}.png';
const jan30 = 'http://home.kieandmike.com:1001/jan30/{z}/{x}/{y}.png';
const mar02 = 'http://home.kieandmike.com:1001/mar02/{z}/{x}/{y}.png';
const mar30 = 'http://home.kieandmike.com:1001/mar30/{z}/{x}/{y}.png';
const jul01 = 'http://home.kieandmike.com:1001/jul01/{z}/{x}/{y}.png';

// JSON
//const countries = 'http://home.kieandmike.com:1001/shp/shp/l0/level_0.json'
//const countries = './shp/l0/level_0.json'
//const countries = 'https://s3.wasabisys.com/satellite.globalmonitor.ch/shp/l0/level_0.json'
//var countries = 'http://tiles.emissions.ch/nitrogendioxide/shapefiles/l0/level_0_no2.json'
//var countries = 'http://tiles.emissions.ch/methane/shapefiles/l0/level_0_ch4.json'

//const countries = './tiles/nitrogendioxide/shapefiles/l0/level_0.json'

let INITIAL_VIEW_STATE = {
  //latitude: 47.4283137,
  //longitude: 8.6869714,
  latitude: 40, 
  longitude: -37,
  //latitude: 39.5,
  //longitude: -98.53,
  zoom: 3,
  maxZoom: 8,
  bearing: 0, 
  pitch: 0
};

let wuhanView = {
  //latitude: 47.4283137,
  //longitude: 8.6869714,
  //latitude: 45, 
  //longitude: 0,
  latitude: 30.43,
  longitude: 114.2,
  zoom: 3,
  maxZoom: 8,
  bearing: 0, 
  pitch: 0
};

const italyView = {
  latitude: 35.665168,
  longitude: 51.389660,
  zoom: 6,
  maxZoom: 8,
  bearing: 0, 
  pitch: 0,
};

const heathrowView = {
  latitude: 27.890591, 
  longitude: -81.466451,
  zoom: 7,
  maxZoom: 8,
  bearing: 0, 
  pitch: 0,
};

let headers = new Headers({
  'Accept-Encoding': 'gzip, deflate',
  'Content-Type': 'application/json',
});

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
        tile: Lights2015,
        mesh: SurfaceMap,
        height: DigitalElevation,
        vecs: false,
        vecsRandom: false,
        searchSwitch: true,
        searchResults: [],
        searchTerm: '',
        searchCoords: [],
        viewState: INITIAL_VIEW_STATE,
        pollution: false,
        choropleth: false,
        view: 'shanghai',
        showModal: false,
        chartData: [],
        chartProvince: '',
        showChart: false,
        date: new Date().toLocaleString('en-CA', {timeZone:'UTC', hour12:false}).replace(',','').substring(0,10),
        pollutionDates: ["2020-01-01"],
        countryData: null,
        scrollValue: 0,
        hoverId_l2: '',
        //hoverId_l2: 'election',
        hoverId_l1: '',
        election: false,
        about: false,
        trendingTopics: [],
        sideBarToggled: true,
        trend: false,
        topicTrends: [],
        topics: {},
        lines: [],
        spatialTrends: {},
        tutorial: false,
        emissions: "nitrogendioxide",
        abr: "no2",
        showTopics: false,
        showSpatialEvents: false,
        topicTrigger: 0,
        eventTrigger: 0,
        newsToggle: true,
        tileToggle: false,
        dataTrigger: 0,
        chartTrigger: 0,
        layer: "mapLayer"
        //shapeFile: countries,
    }
    this.stateSet = this.stateSet.bind(this)
    this.getCoords = this.getCoords.bind(this)
    this.setView = this.setView.bind(this)
    this.setModal = this.setModal.bind(this)
    this.pushState = this.pushState.bind(this)
    this.fetchJson = this.fetchJson.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.changeState = this.changeState.bind(this)
    this.searchView = this.searchView.bind(this)
    this.fetchCities = this.fetchCities.bind(this)
    this.openAbout = this.openAbout.bind(this)
    this.getTopics = this.getTopics.bind(this)
    this.sideTrigger = this.sideTrigger.bind(this)
    this.toggleMaps = this.toggleMaps.bind(this)
    this.toggleNews = this.toggleNews.bind(this)
    this.toggleChoropleth = this.toggleChoropleth.bind(this)
    this.toggleTileMaps = this.toggleTileMaps.bind(this)

    this.getTrends = this.getTrends.bind(this)
    this.getSpatial = this.getSpatial.bind(this)
    this.setEmissions = this.setEmissions.bind(this)
    this.changeView = this.changeView.bind(this)
  }

  componentDidMount() {
    //var countries = 'http://tiles.emissions.ch/' + this.state.emissions + '/shapefiles/l0/level_0_' + this.state.abr +'.json'
    var countries = './tiles/' + this.state.emissions + '/shapefiles/l0/level_0_' + this.state.abr +'.json'
    //var countries = 'http://tiles.emissions.ch/' + this.state.emissions + '/shapefiles/l0/level_0_' + this.state.abr +'.json'

    this.fetchJson(countries)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('tutorial') ==  'true') {
      this.getCoords('20200116', 'wuhan')
      this.setState({'tutorial' : true, 'viewState': wuhanView})
    } else if (urlParams.get('emissions') == 'true') {
      this.setState({'tutorial' : true, 'pollution' : true})
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    var date = moment(this.state.date)
    date = moment(date._i).format('YYYYMMDD')
    if(this.state.date != prevState.date) {

      if(this.state.showTopics == true) {
        console.log('date change')
        this.getTopics(date)
        this.getTrends(date, 'political')
        this.getTrends(date, 'conflict')
        this.getTrends(date, 'medical')
        this.getTrends(date, 'disaster')
        this.getTrends(date, 'other')
      }
      if(this.state.showSpatialEvents == true) {
        this.getSpatial(date)
      }
    }
    if(prevState.showTopics != this.state.showTopics && this.state.showTopics == true) {
      console.log('button click')
      this.getTopics(date)
      this.getTrends(date, 'political')
      this.getTrends(date, 'conflict')
      this.getTrends(date, 'medical')
      this.getTrends(date, 'disaster')
      this.getTrends(date, 'other')
    }
    if(prevState.showSpatialEvents != this.state.showSpatialEvents && this.state.showSpatialEvents == true) {
      this.getSpatial(date)
    }
  }

  toggleMaps = () => this.setState({pollution: !this.state.pollution})

  toggleTileMaps = () => this.setState({tileToggle: !this.state.tileToggle})
  
  toggleNews = () => this.setState({newsToggle: !this.state.newsToggle})

  toggleChoropleth = () => this.setState({choropleth: !this.state.choropleth})

  sideTrigger = () => {
    //console.log(this.state.sideBarToggled)
    this.setState({sideBarToggled: !this.state.sideBarToggled})
  }

  setEmissions = (emission, abr) => {
   //console.log(this.state.emissions)
   // console.log(emission)
    if(this.state.emissions != emission) {
      //var countries = 'http://tiles.emissions.ch/' + emission + '/shapefiles/l0/level_0_' + abr +'.json'
      var countries = './tiles/' + emission + '/shapefiles/l0/level_0_' + abr +'.json'
      //var countries = 'http://satellite.globalmonitor.ch/tiles/' + emission + '/shapefiles/l0/level_0_' + abr +'.json'
      console.log(countries)
      this.fetchJson(countries)
      this.setState({emissions: emission, abr: abr, dataTrigger: this.state.dataTrigger + 1, chartTrigger: this.state.chartTrigger + 1})
    }
  }

  fetchJson = (url) => {
    console.log(url)
    fetch(url, {
      method: 'GET',
      compress: true,
      headers: headers,
    })
    .then(res => res.json())
    .then(json => {
      console.log(json)
      console.log(json.features[0].properties)
      console.log(this.state.emissions)
      let dates = json.features[0].properties.no2Date.split(",")
      this.setState({countryData: json.features, pollutionDates: dates})
    })
  }   

  fetchCities = (type, d1Id, d2Id, view) => {
    console.log('fetchCities')
    if(type == 'District') {
      var url = './tiles/' + this.state.emissions + '/shapefiles/l2/' + d1Id + '_sim.json'
    } else if(type == 'Province') {
      var url = './tiles/' + this.state.emissions + '/shapefiles/l1/' + d1Id + '_sim.json'
    }
    fetch(url) 
    .then(res => res.json())
    .then(json => {
      var data = []
      if(json.features != undefined) {
        json.features.map((feature, index) => {
          if(type == 'District') {
            if(feature.properties.GID_2 == d2Id) {
              data = feature.properties
            }
          } else if(type == 'Province') {
            if(feature.properties.GID_1 == d2Id) {
              data = feature.properties
            }
          }
        })
      }
      var l0String = d1Id.substring(0,3)
      if(l0String != 'Blank') {
        if(type == 'District') {
          this.setState({viewState: view, election: false, chartData: data, chartProvince: d2Id, showModal: true, showChart: true, hoverId_l1: data.GID_0, hoverId_l2: d1Id, dataTrigger: this.state.dataTrigger + 1})
        } else if(type == 'Province') {
          this.setState({viewState: view, election: false, chartData: data, chartProvince: d2Id, showModal: true, showChart: true, hoverId_l1: d1Id, dataTrigger: this.state.dataTrigger + 1})
        }
      } else {
        if(type == 'District') {
          this.setState({viewState: view, chartData: data, chartProvince: d2Id, showModal: true, showChart: true, hoverId_l1: data.GID_0, hoverId_l2: d1Id, dataTrigger: this.state.dataTrigger + 1})
        } else if(type == 'Province') {
          this.setState({viewState: view, chartData: data, chartProvince: d2Id, showModal: true, showChart: true, hoverId_l1: d1Id, dataTrigger: this.state.dataTrigger + 1})
        }
      }
    })
  }   

  searchView = (type, d1Id, d2Id, lat, lon) => {
    ////////console.log(type, d1Id, d2Id, lat, lon)
    lat = Math.round((parseFloat(lat) + Number.EPSILON) * 100) / 100
    lon = Math.round((parseFloat(lon) + Number.EPSILON) * 100) / 100
    let zoom = 5
    if(type == 'District') {
      zoom = 7
    }
    let retView = {
      latitude: lon,
      longitude: lat,
      zoom: zoom,
      maxZoom: 8,
      bearing: 0, 
      pitch: 0,
    }
    this.fetchCities(type, d1Id, d2Id, retView)
    //this.setState({viewState: retView, chartProvince: id, showModal: false, showChart: false, hoverId_l2: id})
  }

  setView = (view, viewState = 0) => {
    let mapView = INITIAL_VIEW_STATE
    let hoverId_l1 = this.state.hoverId_l1
    let hoverId_l2 = this.state.hoverId_l2
    switch(view) {
      case 'italyView': {
        mapView = italyView
        hoverId_l1 = "IRN"
        hoverId_l2 = "IRN.28_1"
        break
      }
      case 'wuhanView': {
        mapView = wuhanView
        //hoverId_l1 = "CHN"
        //hoverId_l2 = "CHN.10_1"
        break
      }
      case 'heathrowView': {
        mapView = heathrowView
        hoverId_l1 = "USA"
        hoverId_l2 = "USA.10_1"
        break
      }
    }
    //this.setState({'view': view, 'viewState': mapView, 'showModal': true, 'hoverId_l1': hoverId_l1, 'hoverId_l2': hoverId_l2})

    //this.setState({'view': view, 'viewState': mapView, 'showModal': true, 'hoverId_l1': hoverId_l1, 'hoverId_l2': hoverId_l2})
  }

  setModal = () => {
    this.setState({'showModal': false, view: ''})
  }

  stateSet = (type, layer, view = 0, key = '', trend = undefined, getCoords = undefined) => {
    let url = '';
    let pollution = false;
    switch(layer) {
      case '2015-lights': {
        url = Lights2015
        break
      }
      case '2016-lights': {
        url = Lights2016
        break
      }
      case 'map': {
        url = SurfaceMap
        break
      }
      case 'pollution': {
        url = Pollution
        pollution = true
        break
      }
      case 'jan01': {
        url = jan01
        pollution = true
        break
      }
      case 'jan15': {
        url = jan15
        pollution = true
        break
      }
      case 'jan30': {
        url = jan30
        pollution = true
        break
      }
      case 'mar02': {
        url = mar02
        pollution = true
        break
      }
      case 'mar30': {
        url = mar30
        pollution = true
        break
      }
      case 'jul01': {
        url = jul01
        pollution = true
        break
      }
    }
    if(type == 'viewState') {
      if(layer == false) {
        ////////console.log('//////console.logial')
        this.setState({'viewState': INITIAL_VIEW_STATE})
      } else {
        ////////console.log('New')
        this.setState({'viewState': view})        
      }
    }
    if(type == 'vecs') {
      url = !this.state.vecs 
    }
    if(type == 'vecsRandom') {
      url = !this.state.vecsRandom 
    }
    if(type == 'searchSwitch') {
      url = !this.state.searchSwitch 
    }
    if(type == 'searchTerm') {
      url = layer
    }
    if(type == 'searchResults') {
      var coordinates = []
      try {
        var searchResults = JSON.parse(layer)
        //console.log(searchResults)
        var lines = searchResults[1]
        searchResults = searchResults[0]
        searchResults.map(result => {
          if(result) {
            coordinates.push(result.Coordinates)
          }
          
        })
        coordinates = coordinates.flat()
        ////////console.log(searchResults.length)
        this.setState({'searchResults': searchResults, 'searchCoords': coordinates, 'searchTerm': key, 'trend': trend, 'lines': lines})
        //this.setState({'searchResults': searchResults, 'searchCoords': coordinates, 'searchTerm': key, 'trend': trend})

      } catch (e) {

      }
      url = layer
    }
    if(type == 'spatialresults') {
      //console.log('hitting spatial')
      var coordinates = []
      try {
        var searchResults = JSON.parse(layer)
        searchResults.map(result => {
          if(result) {
            //console.log(result)
            coordinates.push(result.Latitude + ',' + result.Longitude)
          }
          
        })
        coordinates = coordinates.flat()
        ////////console.log(searchResults.length)
        this.setState({'searchResults': searchResults, 'searchCoords': coordinates, 'searchTerm': key, 'trend': trend})
      } catch (e) {

      }
      url = layer
    }
    if(type == 'searchTrends') {
      var coordinates = []
      try {
        var searchResults = JSON.parse(getCoords)
        searchResults.map(result => {
          if(result) {
            //coordinates.push(result.Coordinates)
            coordinates.push(result.Latitude + ',' + result.Longitude + ',' + result.cluster)
          }
          
        })
        coordinates = coordinates.flat()
        ////////console.log(searchResults.length)
        //console.log('inserting getTrends', layer)
        this.setState({'searchResults': searchResults, 'searchCoords': coordinates, 'topicTrends': layer, 'searchTerm': key, 'trend': trend})
      } catch (e) {

      }
      url = layer
    }
    if(type != 'searchResults' && type != 'searchTrends' && type != 'viewState') {
      ////////console.log('Changing State')
      this.setState({[type] : url, 'pollution': pollution})
    }
  }

  getCoords = (date, key, trend) => {
    console.log('getCoords')
    console.log(date, key)

    if(key != '') {
      const options = {
          //hostname: 'home.kieandmike.com',
          //port: 1003,
          //hostname: 'qufeau368j.execute-api.eu-central-1.amazonaws.com',
          //port: 443, 
          hostname: "globalmonitor.ch/api/",
          //hostname: ".",
          //port: 443,
          path: '/coordinates/' + date + '/' + key,
          //path: '/contour/'
      }

      https.get(options, (response) => {
      
        var result = ''
        response.on('data', function (chunk) {
            result += chunk;
        });

        response.on('error', (err) => console.log(err))
        
        response.on('end', () => {
            if(result != '') {
              //console.log(result)
              //this.setState({searchResults: result})
              if(trend == true) {
                this.getTrends(date, key, result, trend)
              } else {
                this.stateSet('searchResults', result, 0, key, trend)
              }
            }
        });
      });  
    }
  }

  /*getSpatial = (date, key, trend) => {
    if(key != '') {
      const options = {
        hostname: 'home.kieandmike.com',
        port: 1003, 
        path: '/topics/' + date + '/' + key,
      }

      http.get(options, (response) => {
        var result = ''
        response.on('data', function (chunk) {
            result += chunk;
        });

        response.on('error', (err) => console.log(err))
        
        response.on('end', () => {
            if(result != '') {
              ////console.log(result)
              //this.setState({searchResults: result})
              if(trend == true) {
                this.getTrends(date, key, result, trend)
              } else {
                //this.stateSet('spatialresults', result, 0, key, trend)
              }
            }
        });
      });  
    }
  }*/

  getSpatial = (date) => {
    console.log('getSpatial')
    if(this.state.showSpatialEvents == true) {
      if(date != '') {
        const options = {
          //hostname: 'home.kieandmike.com',
          //port: 1003, 
          //hostname: 'qufeau368j.execute-api.eu-central-1.amazonaws.com',
          hostname: "globalmonitor.ch",
          //hostname: ".",
          //port: 443,
          path: '/api/topics/' + date,
        }

        https.get(options, (response) => {
          var result = ''
          response.on('data', function (chunk) {
              result += chunk;
          });

          response.on('error', (err) => console.log(err))
          
          response.on('end', () => {
              if(result != '') {
                result = JSON.parse(result)
                console.log(result)
                let data = []
                result.map((event, index) => {
                  const splitKey = event.key.split(":")
                  let subData = []
                  event.event.map((subMap, sIndex) => {
                    var eventKey = subMap.country
                    var wordList = subMap.text.split(' ')
                    var wordCount = wordList.reduce((r,k)=>{
                      r[k]=1+r[k] || 1
                      return r
                    },{})
                    var i = 0;
                    for (const [key, value] of Object.entries(wordCount)) {
                      //while(i < 50) {
                        var returnObj = {'text':key, 'value':value}

                        subData.push(returnObj)
                        //i = i+1                      
                      //}
                    }
                    subData = subData.sort((a,b) => b.value-a.value)
                    //console.log(subData)
                    const subDataSliced = subData.slice(0,11)
                    //console.log(eventKey, subData)

                    data.push({'country': eventKey, 'text': subDataSliced, 'category':splitKey[0], 'key': splitKey[1]})
                  })
                })
                console.log(data)
                const groupedMap = data.reduce(
                  (r, a) => {
                    r[a.category] = [...r[a.category] || [], a]
                    return r
                  }, {});
                console.log(groupedMap)
                /*const data = [
                  { text: "Hey", value: 1000 },
                  { text: "lol", value: 200 },
                  { text: "first impression", value: 800 },
                  { text: "very cool", value: 1000000 },
                  { text: "duck", value: 10 }
                ];*/
                this.setState({'spatialTrends': groupedMap, eventTrigger: this.state.eventTrigger + 1})
              }
          });
        });  
      }
    }
  }

  getTopics = (date) => {
    //console.log('getTopics')

    //console.log(date)
    const options = {
        //hostname: 'home.kieandmike.com',
        //port: 1003, 
        //hostname: 'qufeau368j.execute-api.eu-central-1.amazonaws.com',
        hostname: "globalmonitor.ch/",
        //hostname: ".",
        //port: 443,
        path: '/api/topics/keys/' + date,
    }

    https.get(options, (response) => {
      var result = ''
      response.on('data', function (chunk) {
          result += chunk;
      });

      response.on('error', (err) => console.log('error', err))
      

      response.on('end', () => {
          if(result != '') {
            result = JSON.parse(result)
            console.log(result)
            this.setState({trendingTopics: result})
            
            //this.stateSet('trendingTopics', result, 0, key)
          }
      });
    });  
  }

  getTrends = (date, key, getCoords, trend) => {
   // console.log('getTrends', date, key, trend)
   console.log('getTrends Triggered')
   if(this.state.showTopics == true) {
      let retObject = this.state.topics
      const options = {
          //hostname: 'home.kieandmike.com',
          //port: 1003, 
          //hostname: 'qufeau368j.execute-api.eu-central-1.amazonaws.com',
          //hostname: 'api.earlywarning.ch',
          hostname: "globalmonitor.ch/",
          //hostname: ".",
          //port: 443,
          path: '/api/topics/trends/' + date + '/' + key,
      }

      http.get(options, (response) => {
        var result = ''
        response.on('data', function (chunk) {
            result += chunk;
        });

        response.on('error', (err) => console.log('error', err))
        
        response.on('end', () => {
            if(result != '') {
              //console.log("trends...")
              //console.log(result)
              result = JSON.parse(result)
              let returnArray = []
              let topicArray = {}
              let oldKey = ""
              result.map((i) => {
                i = i.split("#")
                //console.log(i)
                let key = i[0]
                let topic = i.splice(3, i.length)
                returnArray.push({key: key, topic:topic, positive: i[1], negative: i[2]})
              })
              returnArray = returnArray.sort((a,b) => a.key.localeCompare(b.key))
   

              //console.log(groupedMap)
              //console.log(returnArray)
              //Object.assign(retObject, {key: returnArray})
              retObject[key] = returnArray
              //console.log(Object.keys(retObject))
              //console.log(retObject)
              this.setState({topics: retObject, topicTrigger: this.state.topicTrigger + 1})
              //this.setState({topics: groupedMap})
              //this.stateSet('searchTrends', groupedMap, 0, key, trend, getCoords)

              //this.stateSet('trendingTopics', result, 0, key)
            }
        });
      });  
    }
  }

  pushState = (data, id, layer) => {
    console.log('pushState()')
    console.log(data, id, layer)
    console.log("pushState() still running")
    //if(data.object.properties.GID_1 != undefined) {
    if(data.object !=undefined) {
       var data = data.object
    } 
    if(data.properties.GID_1 != undefined) {
      console.log('0')
      this.setState({'chartProvince': id, chartData: data, 'showModal': true, 'showChart': true, view: '', hoverId_l2: data.properties.GID_1, hoverId_l1: data.properties.GID_0, chartTrigger: this.state.chartTrigger + 1, layer: layer})

    } else {
      console.log('1')
      this.setState({'chartProvince': id, chartData: data, 'showModal': true, 'showChart': true, 'hoverId_l2': 'ATF.4_1', hoverId_l1: data.properties.GID_0, chartTrigger: this.state.chartTrigger + 1, layer: layer})

    }
    
  }

  openAbout = () => {
    this.setState({about: !this.state.about, modal: false})
  }

  changeState = (type) => {
    //////console.log(type, this.state.election)
    if(type == 'choropleth') {
      this.setState({choropleth: !this.state.choropleth})
    } else if(type == 'pollution') {
      this.setState({pollution: !this.state.pollution})
    } else if(type == 'election') {
      if(this.state.election != true) {
        this.setState({election: !this.state.election, viewState: wuhanView})
      } else {
        this.setState({election: !this.state.election})
      }
    }
  }

  changeDate = (date, val) => {
    ////////console.log(date, val)
    this.setState({date: date})
    //this.setState({date: this.state.pollutionDates[val], scrollValue: val})
  }
  
  changeView = (portal) => {
    console.log(portal)
    if(portal == "showSpatialEvents") {
      this.setState({"showSpatialEvents": !this.state.showSpatialEvents})
    }
    if(portal == "showTopics") {
      this.setState({"showTopics": !this.state.showTopics})
    }
  }

  render() {
    if(this.state.tutorial == false) {
      return (
        <div className="App">
          <MapPortal 
            election={this.state.election} 
            hoverId_l1={this.state.hoverId_l1} 
            hoverId_l2={this.state.hoverId_l2} 
            pollution={this.state.pollution} 
            choropleth={this.state.choropleth} 
            date={this.state.date} 
            countryData={this.state.countryData} 
            countries={this.state.shapeFile} 
            pushState={this.pushState} 
            height={this.state.height} 
            viewState={this.state.viewState} 
            controller={true} 
            base={SurfaceMap} 
            tile={pollutionBase}
            mesh={this.state.mesh} 
            vecs={this.state.vecs} 
            vecsRandom={this.state.vecsRandom} 
            searchSwitch={this.state.searchSwitch} 
            searchTerm={this.state.searchTerm} 
            searchResults={this.state.searchResults} 
            searchCoords={this.state.searchCoords} 
            change={this.stateSet} 
            getCoords={this.getCoords}
            lines={this.state.lines}
            emissions={this.state.emissions}
            abr={this.state.abr}
            tileToggle = {this.state.tileToggle}
            dataTrigger = {this.state.dataTrigger}
            layer = {this.state.layer}
          />
          <NavBox 
            search={this.getCoords} 
            election={this.state.election} 
            changeView={this.searchView} 
            changeTiles={this.changeState} 
            pollution={this.state.pollution} 
            choropleth={this.state.choropleth} 
            changeDate={this.changeDate} 
            scrollValue={this.state.scrollValue}
            dates={this.state.pollutionDates} 
            setView={this.setView} 
            tile={this.state.tile} 
            mesh={this.state.mesh} 
            change={this.stateSet} 
            vecs={this.state.vecs} 
            date={this.state.date}
            vecsRandom={this.state.vecsRandom} 
            searchSwitch={this.state.searchSwitch} 
            searchTerm={this.state.searchTerm} 
            searchSwitch={this.state.searchSwitch} 
            searchResults={this.state.searchResults}
            sideTrigger = {this.sideTrigger}  
            emissions={this.state.emissions}
            abr={this.state.abr}
          />

          <SideNav 
            choropleth={this.state.choropleth} 
            toggled={this.state.sideBarToggled}
            openAbout={this.openAbout} 
            trendingTopics={this.state.trendingTopics} 
            mapToggle={this.state.pollution}
            toggleMap={this.toggleMaps}
            //search={this.getSpatial} 
            search={this.getCoords}
            date={this.state.date}
            getTrends={this.getTrends}
            spatialTopics = {this.state.spatialTrends}
            getSpatial={this.getSpatial}
            setEmissions={this.setEmissions}
            emissions={this.state.emissions}
            abr={this.state.abr}
            topics={this.state.topics}
            changeView={this.changeView}
            showTopics = {this.state.showTopics}
            showSpatialEvents = {this.state.showSpatialEvents}
            newsToggle = {this.state.newsToggle}
            toggleNews = {this.toggleNews}
            toggleChoropleth = {this.toggleChoropleth}
            toggleTileMaps = {this.toggleTileMaps}
            tileToggle = {this.state.tileToggle}
          />

          <MapModals 
            tileToggle = {this.state.tileToggle}
            scrollValue={this.state.scrollValue}
            dates={this.state.pollutionDates} 
            getTopics = {this.getTopics} 
            trendingTopics = {this.state.trendingTopics} 
            l0Map={this.state.countryData} 
            searchTerm={this.state.searchTerm} 
            searchResults={this.state.searchResults} 
            search={this.getCoords} 
            sideModal={!this.state.election} 
            openAbout={this.openAbout} 
            about={this.state.about} 
            hoverId_l2={this.state.hoverId_l2} 
            showChart={this.state.showChart}
            modal={true} view={this.state.view} 
            setView={this.setView} 
            showModal={this.state.showModal} 
            setModal={this.setModal} 
            data={this.state.chartData} 
            province={this.state.chartProvince} 
            searchResults={this.state.searchResults}
            mapToggle={this.state.pollution}
            trend={this.state.trend}
            trendList={this.state.topicTrends}
            date={this.state.date}
            emissions={this.state.emissions}
            abr={this.state.abr}
            topics={this.state.topics}
            spatialTopics = {this.state.spatialTrends}
            showSpatialEvents = {this.state.showSpatialEvents}
            showTopics = {this.state.showTopics}
            topicTrigger = {this.state.topicTrigger}
            eventTrigger = {this.state.eventTrigger}
            choropleth={this.state.choropleth} 
            dataTrigger = {this.state.chartTrigger}
          />
          
        </div>
      )
    } else {
      return (
        <MapPortal 
          election={this.state.election} 
          hoverId_l1={this.state.hoverId_l1} 
          hoverId_l2={this.state.hoverId_l2} 
          pollution={this.state.pollution} 
          choropleth={this.state.choropleth} 
          date={this.state.date} 
          countryData={this.state.countryData} 
          countries={this.state.shapeFile} 
          pushState={this.pushState} 
          height={this.state.height} 
          viewState={this.state.viewState} 
          controller={true} 
          base={SurfaceMap} 
          tile={pollutionBase}
          mesh={this.state.mesh} 
          vecs={this.state.vecs} 
          vecsRandom={this.state.vecsRandom} 
          searchSwitch={this.state.searchSwitch} 
          searchTerm={this.state.searchTerm} 
          searchResults={this.state.searchResults} 
          searchCoords={this.state.searchCoords} 
          change={this.stateSet} 
          getCoords={this.getCoords}
          lines={this.state.lines}
          emissions={this.state.emissions}
          abr={this.state.abr}
          topics={this.state.topics}

        />
      )
    }

  }
}

