import React, { Component, useContext } from 'react';
import {Accordion, Card, Modal, Carousel, Container, Row, Col, Form, FormControl, InputGroup, Table, Pagination} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faMinus, faChartBar, faAngleLeft, faAngleRight, faInfo, faFlagUsa, faSearch, faNewspaper, faChartLine, faDownload, faExternalLinkSquareAlt} from '@fortawesome/free-solid-svg-icons'
import {ClearButton, AsyncTypeahead} from 'react-bootstrap-typeahead';
import { Navbar, Nav, Button, NavDropdown} from 'react-bootstrap';
import moment from 'moment'
import { turquoise } from 'color-name';
import * as turf from 'turf'

var GeoJsonGeometriesLookup = require('geojson-geometries-lookup');
var dateFormat = require('dateformat');

//import { ceil } from 'gl-matrix/src/gl-matrix/vec4';

function CustomToggle({eventKey, children }) {
    const currentEventKey = useContext(AccordionContext);
    const accordionIsExpanded = eventKey == currentEventKey
    //console.log(eventKey, children)
    console.debug('currentEventKey', currentEventKey);
    return (
      <div>
        <FontAwesomeIcon
          icon={accordionIsExpanded ? faMinus : faPlus}
        /> 
        <span> </span>
        {children}
      </div>
    );
}

CustomToggle.propTypes = {
    children: PropTypes.object,
};

export default class MapModals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: 'wuhanView',
            data: null,
            chartView: [],
            searchView: [],
            topicView: [],
            spatialView:[],
            grid: {"1":'chartView', "1":"topicView", "3": "searchView", "1": "spatialView"},
            drag: '',
            dragGridClass: 'dropGridPassive',
            activeItem: 0,
            activeEventItem: {},
            activeEventsItemTrigger: 0,
            activeSpatialItem: {},
            activeSpatialItemTrigger: 0,
            searchDate: moment(this.props.date),

        }
        this.handleClose = this.handleClose.bind(this);
        this.chartView = this.chartView.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.searchView = this.searchView.bind(this);
        this.topicView = this.topicView.bind(this);
        this.pageClick = this.pageClick.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.encodeData = this.encodeData.bind(this);
        this.convertToCSV = this.convertToCSV.bind(this);
        this.eventTopicsClick = this.eventTopicsClick.bind(this);
        this.topicCards = this.topicCards.bind(this);
        this.spatialView = this.spatialView.bind(this);
        this.spatialCards = this.spatialCards.bind(this);
        this.spatialTopicsClick = this.spatialTopicsClick.bind(this);
        //this.setView = this.setView.bind(this)
    }

    componentDidUpdate(prevProps, prevState) { 
        ////console.log(prevProps, prevState)
        ////console.log(prevState.searchDate, this.state.searchDate)
        //console.log(this.props, prevProps)
       // console.log(prevState, this.state)
        if(prevProps.trendingTopics != this.props.trendingTopics) {
            //console.log(this.props)
            //console.log(this.props.topics)
            //this.topicView()
            //this.spatialView()
        }

        if(prevState.activeEventsItemTrigger != this.state.activeEventsItemTrigger) {
            this.topicView()
            //this.spatialView()
        }

        if(prevState.activeSpatialItemTrigger != this.state.activeSpatialItemTrigger) {
            //this.topicView()
            this.spatialView()
        }

        if(prevProps.data != this.props.data) {
            console.log('data changed')
        }

        if(prevProps.dataTrigger != this.props.dataTrigger) {
            console.log('dataTriggerChange!!!')
            this.chartView()
        }

        if(this.props.modal == true && this.props.showChart == true && prevProps.data != this.props.data) {
            this.chartView()
            this.searchView()
        } 

        if(prevProps.choropleth != this.props.choropleth) {
            this.chartView()
        }

        //if(this.state.searchDate != prevState.searchDate) {
        if(this.props.scrollValue != prevProps.scrollValue) {
            console.log('Running Search View')
            //this.searchView()
            this.changeDate(this.props.scrollValue - prevProps.scrollValue)
        }

        ////console.log(this.state.drag, this.state.dragGridClass, prevState.mouse, this.state.mouse)
        if(this.state.drag != "" && prevState.mouse != this.state.mouse) {
            var grid = this.state.grid
            grid[this.state.mouse] = this.state.drag
            grid[prevState.mouse] = ""
            this.setState({grid: grid})
        } 

        if(prevProps.searchResults != this.props.searchResults) {
            this.searchView()
        }

        if(prevState.activeItem != this.state.activeItem) {
            this.searchView()
        }
        console.log(prevProps.showTopics, this.props.showTopics, prevProps.topicTrigger, this.props.topicTrigger)
        if(prevProps.topicTrigger != this.props.topicTrigger | prevProps.showTopics != this.props.showTopics) {
            console.log('calling topicView()')
            this.topicView()
        }
        console.log(prevProps.showSpatialEvents, this.props.showSpatialEvents, prevProps.eventTrigger, this.props.eventTrigger)      
        if(prevProps.eventTrigger != this.props.eventTrigger | prevProps.showSpatialEvents != this.props.showSpatialEvents) {
            console.log('calling spatialView()')
            this.spatialView()
        }

    }

    componentDidMount() {
        document.addEventListener('dragend', this.onMouseUp);
        this.searchView()
        //this.topicView()
        //this.spatialView()
    }

    componentWillUnmount() {
        document.removeEventListener('dragend', this.onMouseUp);
    }

    onMouseUp = (event) => {
        if(this.state.dragGridClass == 'dropCellActive') {
            this.setState({dragGridClass: 'dropCellPassive', drag: ''})
        }
    }

    handleClose = () => {
        ////////////console.log('Closing Modal')
        this.props.setModal()
    }

    changeDate = (days) => {
        var date = this.state.searchDate
        ////console.log(date)
        date = moment(date).add(days, 'days')
        ////console.log(date)
        ////console.log(moment(date, 'YYYYMMDD'))
        if(date > moment('2019-12-31')) {
            if(date < moment('2021-01-16')) {
                this.props.search(moment(date).format('YYYYMMDD'), this.props.searchTerm)
                this.setState({searchDate: date})
            }
        }
    }

    chartView = () => {
        //console.log(this.props.data)
        console.log('chartView()')
        if(this.props.data != null && this.props.modal == true && this.props.choropleth == true && this.props.showChart == true) {
            console.log("triggers met for chartView()")
            var data = this.props.data
            console.log(data)
            if(this.props.data != undefined) {
                if(this.props.data.properties != undefined) {
                    data = this.props.data.properties
                }
            } 
            let vals = data.no2Val.split(",").map(x => parseFloat(x))
            if(this.props.abr == "no2") {
                vals = vals.map(x => x * 1e+6)
            } else {
            }
            vals = vals.map(x => parseFloat(x.toFixed(2)))

            let yoyVals = data.no2YoY.split(",").map(x => parseFloat(x))
            yoyVals = yoyVals.slice(365, yoyVals.length)
            console.log("yoyVals modal.js 209 " + yoyVals)
            yoyVals = yoyVals.map(x => parseFloat((x * 100).toFixed(4)))
            console.log("yoyVals modal.js 211 " + yoyVals)

            let dates = data.no2Date.split(",")
            let yoyDates = dates.slice(365, dates.length)

            let chartData = {
                labels: dates,
                datasets: [{
                    label: this.props.abr == "ch4" ? this.props.abr.toUpperCase() + " (ppbv)" : this.props.abr.toUpperCase() + " (μmol/m2)",
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: vals,
                    //backgroundColor: 'orange',
                }]
            }
            console.log(yoyVals)
            let chartYoYData = {
                labels: yoyDates,
                datasets: [{
                    label: this.props.abr.toUpperCase() +' Year on Year Change %',
                    lineTension: 0.1,
                    backgroundColor: 'rgba(229,57,53, .4)',
                    borderColor: 'rgba(229,57,53,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(229,57,53,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(229,57,53,1)',
                    pointHoverBorderColor: 'rgba(229,57,53,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: yoyVals,
                    
                    //backgroundColor: 'orange',
                }]
            }
            //console.log(dates)
            let province = this.props.province
            let lineChart = <Line data={chartData} options={{legend: {
                labels: {
                   fontColor: 'white'
                }
             }, scales: {
                        yAxes: [{
                            ticks: {
                                fontColor: "white",
                                // Create scientific notation labels
                                callback: function(value, index, values) {  
                                                                                                                  
                                    //value = value * 1e+6
                                    
                                    return value.toFixed(0)
                                },
                            },
                            gridLines: {
                                display: false
                                },
                                labels: {
                                show: true,
                                }
                        }],
                        xAxes: [
                            {
                                display: true,
                                gridLines: {
                                display: false
                                }, 
                                ticks: {
                                fontColor: "white"
                                },
                            }
                            ],
            }}} />

            let lineYoYChart = <Line data={chartYoYData} options={{legend: {
                labels: {
                   fontColor: 'white'
                }
             }, scales: {
                yAxes: [{
                    ticks: {
                        fontColor: "white",
                        // Create scientific notation labels
                        callback: function(value, index, values) {                                                                                    
                            //value = value.toFixed(3) * 100 + " %"
                            value = value.toFixed(3) * 1 + " %"
                            return value
                        }
                    }, gridLines: {
                        display: false,
                      },
                      labels: {
                        show: true,
                      },
                }],
                xAxes: [
                    {
                      display: true,
                      gridLines: {
                        display: false
                      }, 
                      ticks: {
                        fontColor: "white"
                      },
                    }
                  ],
                    
            }}} />
            if(this.props.sideModal != true) {
                this.setState({chartView:
                    <Modal show={this.props.showModal} onHide={this.handleClose} centered size='lg' closeButton>
                        <Modal.Header closeButton>
                            <h2>{data.NAME_2 != undefined ? data.NAME_2 + ', ' + data.NAME_1 + ', ' + data.NAME_0 : data.NAME_1 != undefined ? data.NAME_1 + ', ' + data.NAME_0 : data.NAME_0}</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <Carousel indicators={false} nextIcon={  <span aria-hidden="false" className="carousel-control-next-icon" />	}>
                                <Carousel.Item>
                                    {lineChart}
                                </Carousel.Item>
                                <Carousel.Item>
                                    {lineYoYChart}
                                </Carousel.Item>
                            </Carousel>
                        </Modal.Body>
                    </Modal> 
                    })
                
            } else {
                var placeTitle = ''
                if(data.NAME_2 != undefined) {
                    placeTitle = data.NAME_2 + '_' + data.NAME_1 + '_' + data.NAME_0
                } else if(data.NAME_1 != undefined) {
                    placeTitle = data.NAME_1 + '_' + data.NAME_0
                } else {
                    placeTitle = data.NAME_0
                } 
                this.setState({chartView:
                    <div className="fixedCornerChart" draggable="true" 
                        onDragStart={() => this.setState({dragGridClass: 'dropCellActive', drag:'chartView'})} onDragEnd={() => this.setState({drag: ''})}
                    >
                        <h2> <FontAwesomeIcon icon={faChartBar} color='white'/>  {data.NAME_2 != undefined ? data.NAME_2 + ', ' + data.NAME_1 + ', ' + data.NAME_0 : data.NAME_1 != undefined ? data.NAME_1 + ', ' + data.NAME_0 : data.NAME_0} <Button onClick={() => this.encodeData(data, placeTitle, 'emissions')} className='modalDownload'> <FontAwesomeIcon icon={faDownload} color='white'/> </Button></h2>
                        <Accordion defaultActiveKey="0" closeButton>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={1}>
                                <CustomToggle eventKey={1}>
                                    Daily Changes
                                </CustomToggle>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={1}>
                                <Card.Body>
                                    {lineChart}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={2}>
                                    <CustomToggle eventKey={2}>
                                        Year Over Year Changes
                                    </CustomToggle>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={2}>
                                    <Card.Body>
                                        {lineYoYChart}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>                        
                    </div> })
                
            }
           
        } else {
            //////////console.log('returning Div')
            this.setState({chartView: <div /> })
        }
    }

    convertToCSV = (objArray) => {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }
        return str;
    }

    encodeData = (items, fileTitle, type = 'news') => {
        if(type == 'news') {
            items.map(item => {
                item.Coordinates = item.Coordinates.join('#')
                item.Coordinates = item.Coordinates.split(",").join(";")
                item.URL = item.URL.split(",").join("")
            })
        } else if(type == 'emissions') {
            var dates = items.no2Date.split(',')
            var yoy = items.no2YoY.split(',')
            var vals = items.no2Val.split(',')
            var label = fileTitle
            items = []
            dates.map((date, index) => {
                items.push({'location': label, 'date': date, 'yoy_value': yoy[index], 'daily_value': vals[index]})
            })
        }
        console.log(items)
        var headers = Object.keys(items[0])
        items.unshift(headers)
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items)
    
        var csv = this.convertToCSV(jsonObject)
    
        var exportedFilename = fileTitle + '.csv' || 'export.csv'
    
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename)
        } else {
            var link = document.createElement('a')
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportedFilename)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }
    }

    searchView = () => {
        const data = this.props.dates
        const value = parseInt(this.props.scrollValue, 10)
        const allData = this.props.searchResults
        const active = this.state.activeItem * 10
        const endRange = (active) + 9
        const returnData = allData.slice(active, endRange)
        var pagArray = []
        //let date = this.state.searchDate
        let date = data[value]
        const labels = []
        const chartValues = []
        const countryLabels = []
        const countryValues = []

        const items = {}
        const finItems = []
        new Array(24).fill().forEach((acc, index) => {
            items[moment( {hour: index} ).format('HH:mm')] = 0
            items[moment({ hour: index, minute: 15 }).format('HH:mm')] = 0
            items[moment({ hour: index, minute: 30 }).format('HH:mm')] = 0
            items[moment({ hour: index, minute: 45 }).format('HH:mm')] = 0
        })

        if(allData.length > 0) {
            const basePoints = []
            var counts = allData.reduce((p, c) => {
               // console.log(c)
                //const coords = c.Coordinates[0].split(",")
                const coords = [c.Longitude, c.Latitude]
                const coordPoints = turf.point([parseFloat(coords[1]), parseFloat(coords[0])])
                basePoints.push(coordPoints)
                var name = c.Date;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let [key, value] of Object.entries(counts)) {
                key = key.slice(key.length - 4)
                key = moment( key, 'HHmm' )
                items[key.format('HH:mm')] = value
            }

            for (let [key, value] of Object.entries(items)) {
                labels.push(key)
                chartValues.push(value)
            }

            let baseMap = []
            console.log(this.props)
            if(this.props.l0Map != null) {
                this.props.l0Map.map((country, index) => {
                    const countryName = country.properties.NAME_0
                    country.geometry.coordinates.map((coordArray, cIndex) => {
                        var countryPolygon = turf.polygon([coordArray[0]], {'country': countryName})
                        baseMap.push(countryPolygon)
                    })
                })
            }
            
            var points = turf.featureCollection(basePoints)
            var polygons = turf.featureCollection(baseMap)

            // Code for Spatial Join of News Coordinates and Country Spatial Boundaries
            /*var tagged = turf.tag(points, polygons, 'country', 'country');

            var countries = tagged.features.reduce((p, c) => {
                var name = c.properties.country;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            console.log(countries)
            let entries = Object.entries(countries)
            let countriesSorted = entries.sort((a, b) => a[1] - b[1])
            console.log(countriesSorted)

            for (let [key, value] of Object.entries(countries)) {
                countryLabels.push(key)
                countryValues.push(value)
            }*/
        }
        
        // Pie Chart


        // Line Charts
        let chartData = {
            labels: labels,
            datasets: [{
                label: 'Results',
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: chartValues,
            }]
        }

        /*let chartYoYData = {
            labels: dates,
            datasets: [{
                label: 'Nitrogen Dioxide Year on Year Change %',
                lineTension: 0.1,
                backgroundColor: 'rgba(229,57,53, .4)',
                borderColor: 'rgba(229,57,53,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(229,57,53,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(229,57,53,1)',
                pointHoverBorderColor: 'rgba(229,57,53,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: yoyVals,
            }]
        }*/

        let lineChart = <Line data={chartData} options={{legend: {
            labels: {
               fontColor: 'white'
            }
         }, scales: {
                yAxes: [{
                    ticks: {
                        fontColor: "white",
                        // Create scientific notation labels
                        callback: function (value, index, values) {                                                                                    
                            //value = value * 1e+6
                            return value.toFixed(0)
                            
                           
                            //this.props.abr == "ch4" ? return value.toFixed(0) / 10000 : return value.toFixed()

                            //return value.toFixed(0)
                        },
                    },
                    gridLines: {
                        display: false
                        },
                        labels: {
                        show: true,
                        }
                }],
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                        display: false
                        }, 
                        ticks: {
                        fontColor: "white"
                        },
                    }
                    ],
        }}} />

        /*let lineYoYChart = <Line data={chartYoYData} options={{legend: {
            labels: {
               fontColor: 'white'
            }
         }, scales: {
            yAxes: [{
                ticks: {
                    fontColor: "white",
                    // Create scientific notation labels
                    callback: function(value, index, values) {                                                                                    
                        value = value.toFixed(3) * 100 + " %"
                        return value
                    }
                }, gridLines: {
                    display: false,
                  },
                  labels: {
                    show: true,
                  },
            }],
            xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: false
                  }, 
                  ticks: {
                    fontColor: "white"
                  },
                }
              ],
                
        }}} />*/
        var topicArray = []
        if(this.props.trendList.length > 0) {
            this.props.trendList.map((trend, index) => {
                const [negative, positive, ...others] = trend.split('#')
                topicArray.push(others.join(' '))
            })
        }

        return (
            this.setState({
                searchView:
                this.props.searchTerm.length != 0 ? <div className="fixedCornerChart" draggable="true" 
                                                        //onDragStart={() => this.setState({dragGridClass: 'dropCellActive', drag:'searchView'})} onDragEnd={() => this.setState({drag: ''})}
                                                     >
                           
                                 <h2> <FontAwesomeIcon icon={this.props.trend != true ? faNewspaper : faChartLine} color={this.props.trend != true ? 'white' : 'green'} /> {this.props.searchTerm} <Button onClick={() => this.encodeData(allData, this.props.searchTerm)} className='modalDownload'> <FontAwesomeIcon icon={faDownload} color='white'/> </Button></h2>

                            
                     
                        <Accordion defaultActiveKey="0" closeButton>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={1}>
                                <CustomToggle eventKey={1}>
                                    Chart
                                </CustomToggle>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={1}>
                                <Card.Body>
                                    {lineChart}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {this.props.trend == true ? <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={2}>
                                    <CustomToggle eventKey={2}>
                                        Topics 
                                    </CustomToggle>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={2}>
                                    <Card.Body>
                                        {topicArray.length > 0 ?
                                            topicArray.map((trend, index) => <div> {index+1 + '. ' + trend} </div>) 
                                        : null}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> : <div/>}
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={3}>
                                    <CustomToggle eventKey={3}>
                                        URLs
                                    </CustomToggle>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={3}>
                                    <Card.Body>
                                    <Table striped bordered hover variant="dark" responsive>
                                        <thead>
                                            <tr>
                                                <th>Link</th>
                                                <th>Positive</th>
                                                <th>Negative</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                        {returnData.map((row, index) => {
                                            return (                                          
                                                <tr>
                                                    <td>{new URL(row.URL).hostname} <FontAwesomeIcon onClick={() => window.open(row.URL)} icon={faExternalLinkSquareAlt} color='white'/> </td>
                                                    <td>{row.Positive}</td>
                                                    <td>{row.Negative}</td> 
                                                </tr>
                                            )})}
                                        </tbody>
                                    </Table>
                                    <Pagination onClick={this.pageClick}>
                                         
                                        <Pagination.First id={0} />
                                        <Pagination.Prev id={1} />
                                        <Pagination.Item id={2}>{this.state.activeItem + 1} of {Math.floor(this.props.searchResults.length/10) + 1}</Pagination.Item>
                                        <Pagination.Next id={3}/>
                                        <Pagination.Last id={4}/>   
                            
                                    </Pagination>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>                     
                    </div> : <div />
            })
        )
    }

    topicView = () => {
        if(this.props.showTopics == true) {
            // let topicData = {this.props.topics} 
            console.log('topicViewCalled')
            let topicData = JSON.parse(JSON.stringify(this.props.topics));
            if(topicData != undefined) {
                //const allData = Object.entries(topicData)
                let allData = []
                //topicData.map((a,b) => console.log(a,b))
                Object.entries(topicData).map(([key,value])=>{
                    allData.push(key)
                })
                console.log(allData)
                return (
                    this.setState({
                        topicView:
                        allData.length != 0 ? <div className="fixedCornerChart" draggable="true" 
                                                //onDragStart={() => this.setState({dragGridClass: 'dropCellActive', drag:'topicView'})} onDragEnd={() => this.setState({drag: ''})}
                                            >
                                
                            <h2> <FontAwesomeIcon icon={this.props.trend != true ? faNewspaper : faChartLine} color={this.props.trend != true ? 'white' : 'green'} /> Trending Topics </h2>
                                <Accordion defaultActiveKey="0" closeButton>
                                    {allData.map((key, i) => this.topicCards(key, i+1))}
                                </Accordion>                     
                            </div> : <div />
                    })
                )
            }
        } else {
            this.setState({topicView: <div />})
        }
    }

    topicCards = (topic, eventKey) => {
        console.log('topicCards()', topic, eventKey)
        const allData = this.props.topics[topic]
        let pageBase = this.state.activeEventItem[topic]
        let active = this.state.activeEventItem[topic] * 10
        let date = this.props.date
        date = date.split("-").join("")
        if(isNaN(active)){
            pageBase = 0
            active = 0
        }
        const endRange = (active) + 9
        const returnData = allData.slice(active, endRange)
        const paginationLength = allData.length/11
        return (
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            <CustomToggle eventKey={eventKey}>
                                {topic.toUpperCase()}
                            </CustomToggle>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                            <Table striped bordered hover variant="dark" responsive>
                                <thead>
                                    <tr>
                                        <th>Trending Topic</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {returnData.map((row, index) => {
                                    return (                                          
                                        <tr>
                                            <td>
                                                {
                                                    Array.from(row.topic).map((word) => <span className='triggerWord' onClick={() => this.props.search(date, word)}>{word + " "}</span>)
                                                }
                                            </td>
                                        </tr>
                                    )})}
                                </tbody>
                            </Table>
                            <Pagination>
                                <Pagination.First id={0} onClick={() => this.eventTopicsClick(topic, 0)}/>
                                <Pagination.Prev id={1} onClick={() => this.eventTopicsClick(topic, 1)}/>
                                <Pagination.Item id={2} onClick={() => this.eventTopicsClick(topic, 2)}>{pageBase + 1} of {(Math.floor(paginationLength) + 1)}</Pagination.Item>
                                <Pagination.Next id={3} onClick={() => this.eventTopicsClick(topic, 3)}/>
                                <Pagination.Last id={4} onClick={() => this.eventTopicsClick(topic, 4)}/>   
                            </Pagination>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
    }

    spatialView = () => {
        if(this.props.showSpatialEvents == true) {
            // let topicData = {this.props.topics} 
            console.log('spatialViewCalled')
            console.log(this.props)
            const spatialTopicsProp = this.props.spatialTopics
            let topicData = JSON.parse(JSON.stringify(spatialTopicsProp));
            if(topicData != undefined) {
                console.log(topicData)
                //const allData = Object.entries(topicData)
                let allData = []
                //topicData.map((a,b) => console.log(a,b))
                Object.entries(topicData).map(([key,value])=>{
                    allData.push(key)
                })
                console.log(allData)
                return (
                    this.setState({
                        spatialView:
                        allData.length != 0 ? <div className="fixedCornerChart" draggable="true" 
                                                    //onDragStart={() => this.setState({dragGridClass: 'dropCellActive', drag:'spatialView'})} onDragEnd={() => this.setState({drag: ''})}
                                                >
                                
                            <h2> <FontAwesomeIcon icon={this.props.trend != true ? faNewspaper : faChartLine} color={this.props.trend != true ? 'white' : 'green'} /> Trending Events </h2>
                                <Accordion defaultActiveKey="0" closeButton>
                                    {
                                        allData.map((key, i) => this.spatialCards(key, i+1))
                                        //allData.map((key, i) => console.log(key, i))
                                    }
                                </Accordion>                     
                            </div> : <div />
                    })
                )
            }
        } else {
            this.setState({spatialView: <div />})
        }

    }

    spatialCards = (topic, eventKey) => {
        console.log('spatialCards()', topic, eventKey)
        const allData = this.props.spatialTopics[topic]
        let pageBase = this.state.activeSpatialItem[topic]
        let active = this.state.activeSpatialItem[topic] * 10
        let date = this.props.date
        date = date.split("-").join("")
        if(isNaN(active)){
            pageBase = 0
            active = 0
        }
        const endRange = (active) + 9
        const returnData = allData.slice(active, endRange)
        const paginationLength = allData.length/11
        return (
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            <CustomToggle eventKey={eventKey}>
                                {topic.toUpperCase()}
                            </CustomToggle>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                            <Table striped bordered hover variant="dark" responsive>
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Keyword</th>
                                        <th>Topic</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {returnData.map((row, index) => {
                                    let rowTextArray = []

                                    var rowText = row.text.map((text) => {
                                        return text.text
                                    })
                                    console.log(rowText)
                                    console.log(rowTextArray)
                                    return (                                          
                                        <tr>
                                            <td>
                                                {row.country}
                                            </td>
                                            <td>
                                                {<span className='triggerWord' onClick={() => this.props.search(date, row.key)}>{row.key}</span>}
                                            </td>
                                            <td>
                                                {
                                                    rowText.map((word) => <span className='triggerWord' onClick={() => this.props.search(date, word)}>{word + " "}</span>)
                                                }
                                            </td>
                                        </tr>
                                    )})}
                                </tbody>
                            </Table>
                            <Pagination>
                                <Pagination.First id={0} onClick={() => this.spatialTopicsClick(topic, 0)}/>
                                <Pagination.Prev id={1} onClick={() => this.spatialTopicsClick(topic, 1)}/>
                                <Pagination.Item id={2} onClick={() => this.spatialTopicsClick(topic, 2)}>{pageBase + 1} of {(Math.floor(paginationLength) + 1)}</Pagination.Item>
                                <Pagination.Next id={3} onClick={() => this.spatialTopicsClick(topic, 3)}/>
                                <Pagination.Last id={4} onClick={() => this.spatialTopicsClick(topic, 4)}/>   
                            </Pagination>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
    }

    pageClick = (event, id) => {
        //console.log(event.target.id)
        console.log(event.target.id, id)
        if(id == "trends") {
            var idLength = this.props.topics.length
        } else {
            var idLength = this.props.searchResults.length
        }
        if(event.target.id == 0) {
            this.setState({activeItem: 0})
        } else if (event.target.id == 1 & this.state.activeItem != 0) {
            this.setState({activeItem: this.state.activeItem - 1})
        } else if (event.target.id == 3 & this.state.activeItem != Math.floor(idLength/10)) {
            this.setState({activeItem: this.state.activeItem + 1})
        } else if (event.target.id == 4) {
            this.setState({activeItem: Math.floor(idLength/10)})
        }
        //this.setState({activeItem: event.target.id})
    }

    eventTopicsClick = (id, target) => {
        //console.log(event.target.id)
        console.log('eventTopicsClick', id, target)
        const idLength = this.props.topics[id].length
        console.log(this.props.topics[id])
        let events = this.state.activeEventItem
        console.log(events)
        let eventItem = events[id]
        if(isNaN(eventItem)){
            eventItem = 0
        }
        if(target == 0) {
            events[id] = 0
            this.setState({activeEventItem: events, activeEventsItemTrigger: this.state.activeEventsItemTrigger + 1})
        } else if (target == 1 & eventItem != 0) {
            events[id] = eventItem - 1
            this.setState({activeEventItem: events, activeEventsItemTrigger: this.state.activeEventsItemTrigger + 1})
        } else if (target == 3 & eventItem != Math.floor(idLength/11)) {
            events[id] = eventItem + 1
            this.setState({activeEventItem: events, activeEventsItemTrigger: this.state.activeEventsItemTrigger + 1})
        } else if (target == 4) {
            events[id] = Math.floor(idLength/11)
            this.setState({activeEventItem: events, activeEventsItemTrigger: this.state.activeEventsItemTrigger + 1})
        }
    }

    spatialTopicsClick = (id, target) => {
        //console.log(event.target.id)
        const idLength = this.props.spatialTopics[id].length
        console.log(this.props.spatialTopics[id])
        let events = this.state.activeSpatialItem
        console.log(events)
        let eventItem = events[id]
        if(isNaN(eventItem)){
            eventItem = 0
        }
        if(target == 0) {
            events[id] = 0
            this.setState({activeSpatialItem: events, activeSpatialItemTrigger: this.state.activeSpatialItemTrigger + 1})
        } else if (target == 1 & eventItem != 0) {
            events[id] = eventItem - 1
            this.setState({activeSpatialItem: events, activeSpatialItemTrigger: this.state.activeSpatialItemTrigger + 1})
        } else if (target == 3 & eventItem != Math.floor(idLength/11)) {
            events[id] = eventItem + 1
            this.setState({activeSpatialItem: events, activeSpatialItemTrigger: this.state.activeSpatialItemTrigger + 1})
        } else if (target == 4) {
            events[id] = Math.floor(idLength/11)
            this.setState({activeSpatialItem: events, activeSpatialItemTrigger: this.state.activeSpatialItemTrigger + 1})
        }
    }

    /*render() {
        if(this.props.about == true) {
            ////////////console.log('Showing Modal')
            return (
                <Modal show={this.props.about} onHide={this.props.openAbout} centered size='xl' closeButton>
                    <Modal.Header closeButton>
                        <h3>About</h3>
                    </Modal.Header>
                    <Modal.Body className='aboutModalBody' closeButton>
                        <Accordion defaultActiveKey="0" closeButton>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    About This Platform
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    This application uses satellite-based pollution emissions data and media trace data to track changes in social and economic behavior. More information on the application is available at the companion articles listed in "How to Cite". 
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    About This Project
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        This application was developed by Michael Hudecheck and Charlotta Sirén at the University of St Gallen's Global Center for Entrepreneurship and Innovation. It is part of larger effort at the University of St. Gallen in St. Gallen, Switzerland to develop monitoring tools and best practices for emerging epidemiological threats. The <a href="https://data.snf.ch/covid-19/snsf/196239">project</a>, led by Charlotta Sirén, is funded by Swiss National Science Foundation grant SNSF 100018_169426.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    About Our Data
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        The nitrogen dioxide emissions data comes from the European Space Agency (ESA) Sentinel-5 Precursor (Sentinel-5P) TROPOspheric Monitoring Instrument (<a href="https://sentinel.esa.int/web/sentinel/missions/sentinel-5p">Tropomi</a>). The global composites use daily tropospheric nitrogen dioxide readings. The political shape files and pop-up charts use 21-day tropospheric nitrogen dioxide moving averages. All emissions are shown as micromoles per square meter (μmol/m2).    
                                        <br /><br />
                                        This project also uses political boundary data from the Database of Global Administrative Areas (<a href="https://gadm.org/about.html">GADM</a>) and map tiles along with corresponding geospatial data from <a href="http://maps.stamen.com/#terrain/12/37.7706/-122.3782">Stamen Maps</a> and <a href="https://www.openstreetmap.org/about">Open Street Map</a> contributors.   
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How To Cite
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        If you use our platform or the companion article for your research, please cite the following papers
                                        <br /><br />
                                        Hudecheck, M., Sirén, C., Grichnik, D., & Wincent, J. (2020a). How companies can respond to the Coronavirus. MIT Sloan Management Review.
                                        <br /><br />
                                        Hudecheck, M., Sirén, C., Grichnik, D., & Wincent, J. (2020b). Monitoring the COVID-19 crisis from space. MIT Sloan Management Review.
                                    
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                </Modal> 
            )
        } else {
            return(
                <Container className='dragGrid'>
                    <Row>
                        <Col>
                            <Row className='show-grid'>
                                <Col md={4} onDragOver={() => this.setState({mouse: 1})}> 
                                    {
                                        this.state.grid["1"] != undefined ? 
                                            this.state.grid["1"] != "" ? 
                                                this.state[this.state.grid["1"]] : 
                                            <div className={this.state.dragGridClass} /> : 
                                        <div className={this.state.dragGridClass} />
                                    }
                                </Col>
                                <Col md={4} onDragOver={() => this.setState({mouse: 2})}>
                                    {
                                        this.state.grid["2"] != undefined ? 
                                            this.state.grid["2"] != "" ? 
                                                this.state[this.state.grid["2"]] : 
                                            <div className={this.state.dragGridClass} /> : 
                                        <div className={this.state.dragGridClass} />
                                    }
                                </Col>
                                <Col md={4} onDragOver={() => this.setState({mouse: 3})}> {this.state.grid["3"] != undefined ? this.state.grid["3"] != "" ? this.state[this.state.grid["3"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                            </Row> 
                            <Row className='show-grid'>
                                <Col md={4} onDragOver={() => this.setState({mouse: 4})}> {this.state.grid["4"] != undefined ? this.state.grid["4"] != "" ? this.state[this.state.grid["4"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                                <Col md={4} onDragEnter={() => console.log('DragEnter 5')} onDragLeave={() => console.log('DragLeave 5')} onDragOver={() => this.setState({mouse: 5})}> {this.state.grid["5"] != undefined ? this.state.grid["5"] != "" ? this.state[this.state.grid["5"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                                <Col md={4} onDragOver={() => this.setState({mouse: 6})}> {this.state.grid["6"] != undefined ? this.state.grid["6"] != "" ? this.state[this.state.grid["6"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                            </Row>   
                            <Row className='show-grid'> 
                                <Col md={4} onDragOver={() => this.setState({mouse: 7})}> {this.state.grid["7"] != undefined ? this.state.grid["7"] != "" ? this.state[this.state.grid["7"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                                <Col md={4} onDragOver={() => this.setState({mouse: 8})}> {this.state.grid["8"] != undefined ? this.state.grid["8"] != "" ? this.state[this.state.grid["8"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                                <Col md={4} onDragOver={() => this.setState({mouse: 9})}> {this.state.grid["9"] != undefined ? this.state.grid["9"] != "" ? this.state[this.state.grid["9"]] : <div className={this.state.dragGridClass} /> : <div className={this.state.dragGridClass} />}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

            )
        }
       
    }*/

    render() {
        if(this.props.about == true) {
            ////////////console.log('Showing Modal')
            return (
                <Modal show={this.props.about} onHide={this.props.openAbout} centered size='xl' closeButton>
                    <Modal.Header closeButton>
                        <h3>About</h3>
                    </Modal.Header>
                    <Modal.Body className='aboutModalBody' closeButton>
                        <Accordion defaultActiveKey="0" closeButton>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    About This Platform
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    This application uses satellite-based pollution emissions data and media trace data to track changes in social and economic behavior. More information on the application is available at the companion articles listed in "How to Cite". 
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    About This Project
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        This application was developed by Michael Hudecheck and Charlotta Sirén at the University of St Gallen's Global Center for Entrepreneurship and Innovation. It is part of larger effort at the University of St. Gallen in St. Gallen, Switzerland to develop monitoring tools and best practices for emerging epidemiological threats. The <a href="https://data.snf.ch/covid-19/snsf/196239">project</a>, led by Charlotta Sirén, is funded by Swiss National Science Foundation grant SNSF 100018_169426.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    About Our Data
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        The pollution emissions data comes from the European Space Agency (ESA) Sentinel-5 Precursor (Sentinel-5P) TROPOspheric Monitoring Instrument (<a href="https://sentinel.esa.int/web/sentinel/missions/sentinel-5p">Tropomi</a>). The global composites use daily tropospheric emissions readings. The political shape files and pop-up charts use 21-day tropospheric emissions moving averages. All emissions are shown as micromoles per square meter (μmol/m2).    
                                        <br /><br />
                                        This project also uses political boundary data from the Database of Global Administrative Areas (<a href="https://gadm.org/about.html">GADM</a>) and map tiles along with corresponding geospatial data from <a href="http://maps.stamen.com/#terrain/12/37.7706/-122.3782">Stamen Maps</a> and <a href="https://www.openstreetmap.org/about">Open Street Map</a> contributors.   
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How To Cite
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        If you use our platform or the companion article for your research, please cite the following papers
                                        <br /><br />
                                        Hudecheck, M., Sirén, C., Grichnik, D., & Wincent, J. (2020a). How companies can respond to the Coronavirus. MIT Sloan Management Review.
                                        <br /><br />
                                        Hudecheck, M., Sirén, C., Grichnik, D., & Wincent, J. (2020b). Monitoring the COVID-19 crisis from space. MIT Sloan Management Review.
                                    
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                </Modal> 
            )
        } else {
            return(
                <Container className='dragGrid'>
                    <Row>
                        <Col>
                            <Row className='show-grid'>
                                <Col md={4}>
                                    {this.state.chartView}
                                    {this.state.topicView}
                                    {this.state.spatialView}
                                </Col>
                                <Col md={4}>
                                </Col>
                                <Col md={4}>
                                    {this.state.searchView}
                                </Col>
                            </Row> 
                        </Col>
                    </Row>
                </Container>

            )
        }
    }

}