/* eslint-disable max-statements */
import React, { Component } from 'react';
import { Navbar, Nav, Container, Button, Form, NavDropdown, Col} from 'react-bootstrap';
import SearchBar from './typeAhead.js';
import NewsBar from './newsSearchBar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faAngleLeft, faAngleRight, faInfo, faFlagUsa } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var dateFormat = require('dateformat');

export default class NavBox extends Component {
  constructor(props) {
      super(props)
      this.state = {
          tile: '2015-lights',
          mesh: 'map',
          vecs: false,
          vecsRandom: false,
          searchSwitch: true,
          searchResults: 0,
          viewState: false,
          toolTip: 0,
          dateLabel: 0,
          
      }
      this.changeMap = this.changeMap.bind(this)
      this.handleDates = this.handleDates.bind(this)
      this.mobileDate = this.mobileDate.bind(this)
  }

  componentWillMount() {
    this.handleDates()
  }

  componentDidUpdate(previous, newProps) {
    if(this.props.searchResults != previous.searchResults) {
      this.handleDates()
    }
    if(this.props.value != previous.value) {
      this.forceUpdate()
    }
  }

  changeMap = (type, event) => {
    if(this.state.tile == 'pollution') {
      this.props.change(type, '2015-lights')
      this.setState({[type] : '2015-lights'})
    } else {
      this.props.change(type, event)
      this.setState({[type] : event})
    }
  }

  handleDates = () => {
    const data = this.props.dates
    const value = this.props.scrollValue
    const toolTip = data[value]
    var date = Date.parse(toolTip)
    var dateLabel = dateFormat(date, 'fullDate')
    this.setState({toolTip: toolTip, dateLabel: dateLabel})
  }
  
  mobileDate = () => {
    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <FontAwesomeIcon icon={faBars} onClick={onClick} ref={ref}>
        {value}
      </FontAwesomeIcon>
    ));
    return (
      <DatePicker
        selected={new Date(this.props.date)}
        onChange={date => {
          this.props.changeDate(dateFormat(date, 'yyyy-mm-dd'))
        }}         
        customInput={<ExampleCustomInput />}
      />
    );
  };

  render() {
    const data = this.props.dates
    const value = parseInt(this.props.scrollValue, 10)
    let backVal = value - 1
    let forwardVal = value + 1
    if(backVal < 0) {
      backVal = 0
    }
    //////console.log(data)
    //////console.log(data.length)
    //////console.log(value, forwardVal)
    if(forwardVal > data.length) {
      forwardVal = data.length - 1
    }
    //const toolTip = data[value]
    ////////console.log(toolTip)
    //var dateLabel = dateFormat(date, 'fullDate')
    ////////console.log(dateLabel)
    const toolTip = this.state.toolTip
    var date = Date.parse(toolTip)
    //console.log(this.props)
    const dateLabel = this.state.dateLabel
    return(
      <Container fluid>

        {/*Full Screen Nav Bar*/}
        <Navbar bg="dark" variant="dark" className='largeNav'>
          <Button color="white" style={{'marginRight': '1rem'}} onClick={changeEvent => this.props.changeDate(toolTip, backVal)}> <FontAwesomeIcon icon={faAngleLeft} /> </Button>
          <Navbar.Brand href="#home">
          {/*dateFormat(date, 'fullDate')*/}
          
          <Form.Group as={Col} controlId="formGridState" style={{'marginBottom': '0rem', 'paddingLeft': '0rem', 'paddingRight': '0rem'}}>
            <Form.Control as="select" defaultValue={value} value={value} onChange={event => this.props.changeDate(toolTip, event.target.value)}>
              {data.map((d, index) => {
                //console.log(dateFormat(d, 'fullDate'))
                return <option value={index} style={{'marginTop':'.25rem'}}>{dateFormat(d, 'fullDate')}</option>
              })}
            </Form.Control>
          </Form.Group>
          
          </Navbar.Brand>
          <Button color="white" style={{'marginRight': '1rem'}} onClick={changeEvent => this.props.changeDate(toolTip, forwardVal)}> <FontAwesomeIcon icon={faAngleRight} /></Button>

          <Nav className="mr-auto" style={{'width': '70%'}}>
            <Nav.Link className='desktopButton' onClick={() => this.props.setView('italyView')}>Disaster</Nav.Link>
            <Nav.Link className='desktopButton' onClick={() => this.props.setView('wuhanView')}>Recovery</Nav.Link>
            <Nav.Link className='desktopButton' onClick={() => this.props.setView('heathrowView')}>Relapse</Nav.Link>
            <Button color="white" style={{'marginLeft': '1rem'}} onClick={changeEvent => this.props.openAbout()}> <FontAwesomeIcon icon={faInfo} /> </Button>
            <SearchBar changeView={this.props.changeView} style={{'marginRight':'1rem'}} />
          </Nav>
          <Nav className="ml-auto">
          
          </Nav>
        </Navbar>

         {/*Tablet Nav Bar*/}
        <Navbar bg="dark" variant="dark" className='medNav'>
            <Navbar.Brand href="#home">
              <Button color="white" style={{'marginRight': '.5rem'}} onClick={this.props.sideTrigger}> <FontAwesomeIcon icon={faBars} /> </Button>

              <div style={{"margin": "auto"}}> {this.props.election == false ? 'Global Monitor' : 
              <div><FontAwesomeIcon icon={faFlagUsa} color='white'/> HSG Election Insights</div>} </div>

            </Navbar.Brand>
         
          <Nav className="mr-auto navSearch">
            <SearchBar changeView={this.props.changeView} />
            <NewsBar date={this.props.date} search={this.props.search} />
            {/*<NavDropdown title="Views" id="nav-dropdown">
              <NavDropdown.Item eventKey="4.1">Pollution</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Lights</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3">Maps</NavDropdown.Item>
            </NavDropdown>*/}
          </Nav>
          <Navbar.Brand href="#home">
          <DatePicker 
            onChange={date => {
              this.props.changeDate(dateFormat(date, 'yyyy-mm-dd'))
            }} 
            selected={new Date(this.props.date)}
          />

            {/*<Button color="white" onClick={changeEvent => this.props.changeDate(toolTip, backVal)}> <FontAwesomeIcon icon={faAngleLeft} /> </Button>
              <Form.Group as={Col} controlId="formGridState" style={{'margin': 'auto'}}>
                {dateFormat(data[value], 'yyyy-mm-dd')}
              </Form.Group>
              <Button color="white" style={{'marginRight': '-.25rem' }} onClick={changeEvent => this.props.changeDate(toolTip, forwardVal)}> <FontAwesomeIcon icon={faAngleRight} /></Button>
          
          */}
           </Navbar.Brand>
          
        </Navbar> 

        {/*Mobile Nav Bar*/}
        <Navbar bg="dark" variant="dark" className='smallNav'>
          <Nav className="mr-auto" style={{'width': '100%'}}>
            <Button color="white" style={{'marginRight': '.5rem'}} onClick={this.props.sideTrigger}> <FontAwesomeIcon icon={faBars} /> </Button>

            {/*<Button color="white" style={{'marginRight': '.5rem'}} onClick={() => this.props.setView('italyView')}> 1 </Button>
            <Button color="white" style={{'marginRight': '.5rem'}} onClick={() => this.props.setView('wuhanView')}> 2 </Button>
            <Button color="white" style={{'marginRight': '.5rem'}} onClick={() => this.props.setView('heathrowView')}> 3 </Button>*/}
            <NewsBar date={this.props.date} search={this.props.search} />
          </Nav>
          {this.mobileDate()}
          {/*<Button size='sm' variant={this.props.election == true ? "danger" : "primary"} style={{'marginLeft': '.5rem'}} onClick={() => this.props.changeTiles('election')}> Election  </Button>*/}
        </Navbar> 
        <div>
        {/*this.props.pollution == true ? <div className="fixed-bottom">
          <div className="scaleContainer">
            <div className="colorScale">
              <div className="scaleText"> 10 <sub> µmol/m2</sub></div> 
              <div className="scaleText">250 <sub> µmol/m2</sub></div> 
              <div className="scaleText">500 <sub> µmol/m2</sub></div> 
            </div>
          </div>
          </div> : <div/>*/}
        <div className="fixed-bottom">
          <div className="osmAttrib">
            Platform ©Authors, Map Tiles ©Stamen Design, Tile Data ©OpenStreetMap Contributors
          </div>
        </div>
        </div>
    </Container>
    )
  }
}
