import React, { Fragment, Component } from 'react';
import {ClearButton, AsyncTypeahead} from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';
const SEARCH_URI = './search';

export default class SearchBar extends Component {
  constructor(props) {
      super(props)
      this.state = {
          isLoading: false,
          //setIsLoading: false,
          options: [],
          viewCoords:{},
          //setOptions: [],
      }
      this.handleSearch = this.handleSearch.bind(this)
  }


    handleSearch = (query) => {
        console.log(query)
        console.log(SEARCH_URI)
        fetch(`${SEARCH_URI}/${query}`)
        .then((resp) => resp.json())
        .then((items) => {
            ////console.log(items)ß
            let returnArray = []
            let viewCoords = {}
            items.map((i) => {
                var itemString = i.split('#')
                returnArray.push(itemString[0])
                //console.log(itemString)
                if(itemString[1] == 'District') {
                    viewCoords[itemString[0]] = [itemString[1], itemString[2], itemString[3], itemString[4], itemString[5]]
                } else if(itemString[1] == 'Province') {
                    //viewCoords[itemString[0]] = [itemString[1], "", itemString[2], itemString[3], itemString[4]]
                    viewCoords[itemString[0]] = [itemString[1], itemString[2], itemString[3], itemString[4], itemString[5]]
                } else {
                    viewCoords[itemString[0]] = [itemString[1], "", itemString[2], itemString[3], itemString[4]]
                }
               
            })
            this.setState({options: returnArray, isLoading: false, viewCoords: viewCoords})
        });
    };

    render() {
        const viewCoords = this.state.viewCoords
        return (
            <div className='typeAhead'>
                <AsyncTypeahead
                    id="searchBar"
                    isLoading={this.state.isLoading}
                    labelKey="login"
                    minLength={3}
                    onSearch={(event) => {
                        this.handleSearch(event)
                        this.setState({isLoading: true})
                    }}
                    options={this.state.options}
                    placeholder="Search for a location..."
                    onChange={(selected) => viewCoords[selected] != undefined ? this.props.changeView(viewCoords[selected][0], viewCoords[selected][1], viewCoords[selected][2], viewCoords[selected][3], viewCoords[selected][4]) : null}

                    renderMenuItemChildren={(option, props) => {
                        return (
                            <Fragment>
                                <span>{option}</span>
                            </Fragment>
                        )
                    }}
                >
                    {({ onClear, selected }) => (
                        <div className="rbt-aux">
                        {!!selected.length && <ClearButton onClick={onClear} />}
                        {/*!selected.length && <Spinner animation="grow" size="sm" />*/}
                        </div>
                    )}
                </AsyncTypeahead>
            </div>
        )
    }
}
