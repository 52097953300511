import React, { Fragment, Component } from 'react';
import {ClearButton, AsyncTypeahead} from 'react-bootstrap-typeahead';
import {Accordion, Card, Modal, Carousel, Container, Row, Col, Form, FormControl, InputGroup, Table, Pagination} from 'react-bootstrap';
import {faPlus, faMinus, faChartBar, faAngleLeft, faAngleRight, faInfo, faFlagUsa, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import moment from 'moment'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


import { Spinner } from 'react-bootstrap';
const SEARCH_URI = 'http://home.kieandmike.com:1000/search';

export default class NewsBar extends Component {
    constructor(props) {
    super(props)
    this.state = {
        isLoading: false,
        //setIsLoading: false,
        options: [],
        viewCoords:{},
        //setOptions: [],
        calendarFocused: true,
        search: null,
    }
    this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.date != prevProps.date) {
            //console.log("Updating Date")
            if(this.state.search != null) {
                var date = this.props.date
                date = date.split("-").join("")
                this.props.search(date, this.state.search)
            }
        }
    }

    handleSearch = (query) => {

        fetch(`${SEARCH_URI}/${query}`)
        .then((resp) => resp.json())
        .then((items) => {
            ////console.log(items)
            let returnArray = []
            let viewCoords = {}
            items.map((i) => {
                var itemString = i.split('#')
                returnArray.push(itemString[0])
                //console.log(itemString)
                if(itemString[1] == 'District') {
                    viewCoords[itemString[0]] = [itemString[1], itemString[2], itemString[3], itemString[4], itemString[5]]
                } else if(itemString[1] == 'Province') {
                    //viewCoords[itemString[0]] = [itemString[1], "", itemString[2], itemString[3], itemString[4]]
                    viewCoords[itemString[0]] = [itemString[1], itemString[2], itemString[3], itemString[4], itemString[5]]
                } else {
                    viewCoords[itemString[0]] = [itemString[1], "", itemString[2], itemString[3], itemString[4]]
                }
                
            })
            this.setState({options: returnArray, isLoading: false, viewCoords: viewCoords})
        });
    
    
    };

    render() {
        const start = moment('2016-01-1')
        const end = moment('2020-01-01')
        const viewCoords = this.state.viewCoords
        var date = this.props.date
        //console.log(date)
        date = date.split("-").join("")
        //console.log(date)
        return (
            <div className='typeAhead'>
                <Form inline onSubmit={event => { event.preventDefault()}}>
                    <Form.Group as={Col}>
                        <InputGroup>
                            <Form.Control type="text" placeholder="Search for a topic..." className="mr-sm-2" onKeyPress={(event) => {
                                //log(event.target.value)
                                    if(event.key == 'Enter') {
                                        //this.props.search('20200101', event.target.value)
                                        this.props.search(date, event.target.value)
                                        this.setState({search: event.target.value})
                                    }
                                }} 
                                icon={<Spinner animation="grow" size="sm" />}
                            
                            >
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                </Form>

            </div>        
        )
    }
}
