/* eslint-disable max-statements */
import React, { Component } from 'react';
//import { slide as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCloud, faNewspaper, faFire, faChartLine, faInfo, faLayerGroup, faSatellite, faBorderAll} from '@fortawesome/free-solid-svg-icons'
import { ProSidebar, SidebarContent, SidebarFooter, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import moment from 'moment';
import 'react-pro-sidebar/dist/css/styles.css';
import { Stage, Layer, Rect, Text } from 'react-konva';
import Konva from 'konva';
import WordCloud from "react-d3-cloud";

var cloud  = require('d3-cloud');

export default class SideNav extends Component {
  constructor(props) {
      super(props)
      this.state = {

      }

  }

  componentWillMount() {
  }

  componentDidUpdate(previous, newProps) {
  }

  /*render() {
    return (
        <Menu>
          <a id="home" className="menu-item" href="/">Home</a>
          <a id="about" className="menu-item" href="/about">About</a>
          <a id="contact" className="menu-item" href="/contact">Contact</a>
          <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a>
        </Menu>
    )
  }*/

  render() {
      ////console.log(this.props.trendingTopics)
      ////console.log(this.props.trendingTopics.length)
      var trendsArray = []
      var spatialArray = []
      //console.log(this.props.spatialTopics)
      if(this.props.trendingTopics.length > 0) {
          //////console.log(this.props.trendingTopics)
        this.props.trendingTopics.map(trend => trendsArray.push(trend))
      }

      if(this.props.spatialTopics.length > 0) {
        //////console.log(this.props.trendingTopics)
        this.props.spatialTopics.map(trend => spatialArray.push(trend))
      }
      //console.log(trendsArray)
      //console.log(this.props.topics)
      /*for(let [key, topics] of this.props.topics.entries()) {
        console.log(key)
        console.log(topics)
      }*/
      //console.log(this.props.topics.size)
      ////console.log(this.props.date, moment(this.props.date).format('YYYYMMDD'))
      return (
        <ProSidebar collapsed={this.props.toggled}>
            <SidebarContent>

                <Menu iconShape="square">
                <div>
                  <MenuItem onClick={this.props.toggleNews} className={this.props.newsToggle == true ? 'iconToggled' : 'none'} icon={<FontAwesomeIcon icon={faNewspaper} />}>Dashboard</MenuItem>
                                      {/*trendsArray.length > 0 ? trendsArray.map((trend, index) => {
                        //return <MenuItem onClick={() => this.props.search(moment(this.props.date).format('YYYYMMDD'), trend, true)}>{trend}</MenuItem>
                        //return <MenuItem onClick={() => this.props.search(moment(this.props.date).format('YYYYMMDD'), trend)}>{trend}</MenuItem>

                    */}

                  {this.props.newsToggle == true ? 
                    <div>
                      <MenuItem onClick={() => this.props.changeView("showSpatialEvents")} className={this.props.showSpatialEvents == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faFire}  />} title="Events" />
                      <MenuItem onClick={() => this.props.changeView("showTopics")} className={this.props.showTopics == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faChartLine}  />} title="Trending" />
                    </div>
                  : <div>
                    </div>}
                  <hr />
                </div>  
                <div>
                  
                  <MenuItem onClick={this.props.toggleMap} className={this.props.mapToggle == true ? 'iconToggled' : 'none'}  icon={<FontAwesomeIcon icon={faSatellite} />} title="Satellite Data">Satellite Data</MenuItem>
                    {
                    
                      /*this.props.mapToggle == true ? <div>
                        <MenuItem onClick={this.props.toggleChoropleth}  className={this.props.choropleth == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faBorderAll} />} title="Country Aggregates">Country Aggregates</MenuItem>
                        <MenuItem onClick={this.props.toggleTileMaps}  className={this.props.tileToggle == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faLayerGroup} />} title="Map Tiles">Map Tiles</MenuItem>
                        <hr />

                        <MenuItem className={this.props.emissions == 'nitrogendioxide' ? 'subIconToggled' : 'none'} onClick={() => this.props.setEmissions('nitrogendioxide', 'no2')} icon={<div> NO<sub>2</sub> </div>} title="Nitrogen Dioxide">Nitrogen Dioxide</MenuItem>
                        <MenuItem className={this.props.emissions == 'methane' ? 'subIconToggled' : 'none'} onClick={() => this.props.setEmissions('methane', 'ch4')} icon={<div> CH<sub>4</sub> </div>} title="Methane" >Methane</MenuItem>
                        <MenuItem icon={<div> SO<sub>2</sub> </div>} title="Sulfor Dioxide" >Sulfor Dioxide</MenuItem>
                        <MenuItem icon={<div> O<sub>3</sub> </div>} title="Ozone">Ozone</MenuItem>
                        <MenuItem icon={<div class='pro-icon-wrapper-sub'> CH<sub>2</sub>O </div>} title="Formaldehyde" >Formaldehyde</MenuItem>
                        <MenuItem icon={<div> CO </div>} title="Carbon Monoxide" >Carbon Monoxide</MenuItem>
                      </div>
                      : <div></div>*/
                  
                      this.props.mapToggle == true ? <div>
                        <MenuItem onClick={this.props.toggleChoropleth}  className={this.props.choropleth == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faBorderAll} />} title="Country Aggregates">Country Aggregates</MenuItem>
                        <MenuItem onClick={this.props.toggleTileMaps}  className={this.props.tileToggle == true ? 'subIconToggled' : 'none'} icon={<FontAwesomeIcon icon={faLayerGroup} />} title="Map Tiles">Map Tiles</MenuItem>
                        <hr />
                        <MenuItem className={this.props.emissions == 'nitrogendioxide' ? 'subIconToggled' : 'none'} onClick={() => this.props.setEmissions('nitrogendioxide', 'no2')} icon={<div> NO<sub>2</sub> </div>} title="Nitrogen Dioxide">Nitrogen Dioxide</MenuItem>
                        <MenuItem className={this.props.emissions == 'methane' ? 'subIconToggled' : 'none'} onClick={() => this.props.setEmissions('methane', 'ch4')} icon={<div> CH<sub>4</sub> </div>} title="Methane" >Methane</MenuItem>

                      </div>
                      : <div></div>
                  
                  }
                </div>
              </Menu>

            </SidebarContent>

            <SidebarFooter>
                <Menu iconShape="square">
                    <MenuItem onClick={changeEvent => this.props.openAbout()} icon={<FontAwesomeIcon icon={faInfo} />}> Information     </MenuItem>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
      ) 
  }
}
