/* eslint-disable max-statements */
import React, { Component, useState, useCallback } from 'react';
import DeckGL from '@deck.gl/react';
import {HexagonLayer, ScreenGridLayer, ContourLayer} from '@deck.gl/aggregation-layers';
import {TerrainLayer, TileLayer, TripsLayer} from '@deck.gl/geo-layers';
import {BitmapLayer, GeoJsonLayer, IconLayer, PolygonLayer, PathLayer} from '@deck.gl/layers';
import {fxaa, pbr} from '@luma.gl/shadertools';
import {PostProcessEffect} from '@deck.gl/core';
import { phongLighting } from '@luma.gl/shadertools/dist/es5/modules';
import data from '../json/exportGeo.json'
import {rgb} from 'd3-color';
import {View, AmbientLight, PointLight, LightingEffect, PerspectiveView} from '@deck.gl/core';
import {OrthographicView} from '@deck.gl/core';
import {Deck, MapView} from '@deck.gl/core';
import { Button, Popover, OverlayTrigger, Overlay} from 'react-bootstrap';
import MapGL, { Popup } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRepublican, faDemocrat, faQuestionCircle, faSmog, faIndustry, faInfo } from '@fortawesome/free-solid-svg-icons'
import {StaticMap} from 'react-map-gl';
import * as turf from '@turf/turf'
import hull from 'hull.js';

var testData = require('./kde.json')
testData = JSON.stringify(testData)
//console.log(testData)
var clustering = require('density-clustering');
var dbscan = new clustering.DBSCAN();

let numZeroesAfterPoint = (x)  => {
  if (x % 1 == 0) {
    return 0;
  } else {
    return -1 - Math.floor(Math.log10(x % 1));
  }
}

const ICON_MAPPING = {marker: {x: 0, y: 0, width: 128, height: 128, mask: true}}

const devicePixelRatio = (typeof window !== 'undefined' && window.devicePixelRatio) || 1;
//import countryData from '../countries_lvl_0.geo.json';

// Set View State
let random = data.sort(() => .5 - Math.random()).slice(0,1000)

// Set Elevation
const ELEVATION_DECODER = {
  rScaler: 500,
  gScaler: 0,
  bScaler: 0,
  offset: 0,
};

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70]
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1
});

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000]
});

const lightingEffect = new LightingEffect({ambientLight, pointLight});

const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect]
};

const CONTOURS = [
  {threshold: 1, color: [255, 0, 0], strokeWidth: 4},
  {threshold: 5, color: [0, 255, 0], strokeWidth: 2},
  {threshold: [6, 10], color: [0, 0, 255, 128]}
]

function isLatitude(lat) {
  return isFinite(lat) && Math.abs(lat) <= 90;
}

function isLongitude(lng) {
  return isFinite(lng) && Math.abs(lng) <= 180;
}

// Normalize Values
function normalize(min, max) {
  var delta = max - min;
  return function (val) {
      return (val - min) / delta;
  };
}
 
export default class MapPortal extends Component {
  constructor(props) {
      super(props)
      this.state = {
          elevation: ELEVATION_DECODER,
          wireframe: false,
          initialViewState: this.props.viewState,
          time: 0, 
          searchCoords: [],
          hoverId: 0,
          date: new Date().toLocaleString('en-CA', {timeZone:'UTC', hour12:false}).replace(',','').substring(0,10),
          popupX: 0,
          popupY: 0,
          hover:{x:0, y:0},
          donPercent: 0,
          donVotes: 0,
          county: "",
          state: "",
          county: "", 
          picked: false,
          yoyChange: 0, 
          lockdownChange: 0,
          joePercent: 0,
          joeVotes: 0,
          coordX: 0,
          coordY: 0,
          screenX: 100,
          screenY: 200,

      }
      this.meshLayer = this.meshLayer.bind(this)
      this.tileLayer = this.tileLayer.bind(this)
      this.pathLayer = this.pathLayer.bind(this)
      this.colorToRGBArray = this.colorToRGBArray.bind(this)
      this.mapLayer = this.mapLayer.bind(this)
      this.iconLayer = this.iconLayer.bind(this)
      //this.getCoords = this.f.bind(this)
      this.hexagonLayer = this.hexagonLayer.bind(this)
      this.screenGridlayer = this.screenGridlayer.bind(this)
      this.clusterLayer = this.clusterLayer.bind(this)
      this.contourLayer = this.contourLayer.bind(this)
      this.lightLayer = this.lightLayer.bind(this)
  }

  componentDidUpdate = (previousProps, newProps) => {
    console.log("map updating...")
    console.log(this.props.emissions)
    console.log(this.props)
    if(previousProps.dataTrigger != newProps.dataTrigger && this.props.choropleth == true) {
      //this.mapLayer() 
      //this.stateLayer() 
      //this.cityLayer() 
    }

    //console.log(new Date().toLocaleString('en-CA', {timeZone:'UTC', hour12:false}).replace(',','').substring(0,10))
  }

  colorToRGBArray = (color) => {
    if (Array.isArray(color)) {
      return color.slice(0, 4);
    }
    const c = rgb(color);
    return [c.r, c.g, c.b, 255];
  }

  meshLayer = () => {
    const mesh = new TerrainLayer({
        id: 'heightLayer',
        minZoom: 0,
        maxZoom: 8,
        strategy: 'best-available',
        elevationDecoder: this.state.elevation,
        elevationData: this.props.height,
        texture: this.props.mesh,
        wireframe: this.state.wireframe,
        meshMaxError: 10,
        opacity: 1,
        color: [255, 255, 255], 
        parameters: {
          depthTest: false,
        }, material: {
    
        }
      })
      return mesh
  }

  pathLayer = () => {
    //////////////////console.log()
    const theme = DEFAULT_THEME
    let layer;
    if(this.props.vecs == true) {
      if(this.props.vecsRandom == false) {
        layer = new TripsLayer({
          id: 'trips',
          //data:  'https://raw.githubusercontent.com/uber-common/deck.gl-data/master/examples/trips/trips-v7.json',
          data: random,
          getPath: d => d.path,
          getTimestamps: d => d.timestamps,
          getColor: d => (d.timestamps > 300 ? theme.trailColor1 : theme.trailColor0),
          opacity: 0.2,
          widthMinPixels: 2,
          rounded: true,
          trailLength: 18000,
          currentTime: this.state.time,
          shadowEnabled: false
        })
      } else {
        layer = new TripsLayer({
          id: 'trips',
          //data:  'https://raw.githubusercontent.com/uber-common/deck.gl-data/master/examples/trips/trips-v7.json',
          data, 
          getPath: d => d.path,
          getTimestamps: d => d.timestamps,
          getColor: d => (d.timestamps > 300 ? theme.trailColor1 : theme.trailColor0),
          opacity: 0.2,
          widthMinPixels: 2,
          rounded: true,
          trailLength: 18000,
          currentTime: this.state.time,
          shadowEnabled: false
        })
      }
      return layer 
    } else {
      return null
    }

  }

  mapLayer = () => {
    //console.log('Updating mapLayer()')
    const data = this.props.countryData
    var opacity = 0
    var selected = 0 
    const layer = new GeoJsonLayer({ 
      id: 'tiles-for-main-geojson-layer',
      data,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: false,
      dataComparator: (newData, oldData) => {
        if(newData != oldData) {
          if(newData != undefined && this.props.layer == "mapLayer") {
            newData.map((country) => {
              if(country.properties.GID_0 == this.props.hoverId_l1) {
                this.props.pushState(country, country.properties.GID_0, "mapLayer")
              }
            })
          }
        }
      },
      onClick: (info, event) => {
        if(info.object != undefined) {
          this.props.pushState(info, info.object.properties.GID_0, "mapLayer")
        } 
      },
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      getFillColor: d => {
        if(d.properties.no2Date != null) {
          let vals = d.properties.no2Scale.split(",")
          let dates = d.properties.no2Date.split(",")
          let result = Object.assign(...dates.map((k, i) => ({[k]: vals[i]})))
          let scale = result[this.props.date]
          //return [25, 25, 112, scale * 350]
          return [139, 0, 0, 0]
        } else {
          //return [25, 25, 112, 0]
          return [139, 0, 0, 0]
        }

      }, 
      autoHighlight: true,
      getLineColor: [139, 0, 0, 0],
      getRadius: 2,
      getLineWidth: 2,
      parameters: {
        depthTest: false,
      },
      updateTriggers: {
        getFillColor:  [d => {
          if(d.properties.no2Date != null) {
            let vals = d.properties.no2Scale.split(",")
            let dates = d.properties.no2Date.split(",")
            let result = Object.assign(...dates.map((k, i) => ({[k]: vals[i]})))
            let scale = result[this.props.date]
            //return [25, 25, 112, scale * 350]
            return [139, 0, 0, scale * 250]
          } else {
            //return [25, 25, 112, 0]
            return [139, 0, 0, 0]
          }
        }]
      }
    });
    return layer
  }

  stateLayer = () => {
    console.log('stateLayer()')
    //const data = 'http://tiles.emissions.ch/' + this.props.emissions + '/shapefiles/l1/'  + this.props.hoverId_l1 + '_' + this.props.abr + '.json'
    const data = './tiles/' + this.props.emissions + '/shapefiles/l1/'  + this.props.hoverId_l1 + '_' + this.props.abr + '.json'

    var opacity = 0
    var selected = 0 
    const layer = new GeoJsonLayer({
      id: 'tiles-for-main-geojson-layer2',
      data,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: false, 
      dataComparator: (a, b) => {
        if(a != b) {
          console.log('stateLayer() data change')
          console.log(this.props.hoverId_l2)
          console.log(a)
          if(a.features != undefined && this.props.layer == "stateLayer") {
            a.features.map((feature) => {
              if(feature.properties.GID_1 == this.props.hoverId_l2) {
                console.log('pushing stateLayer() to pushState()')
                console.log(feature)
                this.props.pushState(feature, this.props.hoverId_l1, "stateLayer")
              }
            })
          }
        }
      },
      onHover: (d, event) => {
        console.log(d)
        if(d.object != undefined) {
          console.log(d.object)
          /*let vals = d.object.properties.no2YoY.split(",").map(Number)
          vals = vals.filter( value => !Number.isNaN(value))
          let dates = d.object.properties.no2Date.split(",")
          let dateIndex = dates.indexOf(this.props.date)
          if(dateIndex > 365) {
            var startIndex = 365
          } else if(dateIndex = -1) {
            startIndex = dates.length - 365
            dateIndex = dates.length
          } else {
            startIndex = 22
          }
          let y1MaVals = vals.slice(startIndex-21, startIndex)
          let y2MaVals = vals.slice(dates.length-21, dates.length)

          let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          var reduction = (reduction2 / reduction1)-1
          var reduction = vals.reduce((a,v,i)=> (a*i+v)/(i+1))
          var reduction = vals[dates.indexOf(this.props.date)]*/
          let vals = d.object.properties.no2Val.split(",").map(Number)
          let dates = d.object.properties.no2Date.split(",")
          let dateIndex = dates.indexOf(this.props.date)
          if(dateIndex > 365) {
            var startIndex = 365
          } else if(dateIndex = -1) {
            startIndex = dates.length - 365
            dateIndex = dates.length
          } else {
            startIndex = 22
          }
          console.log("Reducing...")
          let y1MaVals = vals.slice(startIndex-21, startIndex)
          let y2MaVals = vals.slice(dates.length-21, dates.length)
          let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          let reduction = (reduction2/reduction1)-1
          this.setState({picked: true, yoyChange: reduction, hover: event.srcEvent, state: d.object.properties.NAME_1, country: d.object.properties.NAME_0, hoverLevel: 'state'})
        } else {
          this.setState({picked: false})
        }
      },
      onClick: (info, event) => {
        console.log('stateLayer() click')
        console.log(info)
        this.props.pushState(info, this.props.hoverId_l1, "stateLayer")
        if(info.object != undefined) {
          this.props.pushState(info, info.object.properties.GID_1, "stateLayer")
        }
      },
      onChange: (info, event) => {
        console.log(info, event)
      },
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      getFillColor: d => {
        if(d.properties.no2Date != null) {
          let vals = d.properties.no2Val.split(",").map(Number)
          let dates = d.properties.no2Date.split(",")
          let dateIndex = dates.indexOf(this.props.date)
          if(dateIndex > 365) {
            var startIndex = 365
          } else if(dateIndex = -1) {
            startIndex = dates.length - 365
            dateIndex = dates.length
          } else {
            startIndex = 22
          }
          console.log("Reducing...")
          let y1MaVals = vals.slice(startIndex-21, startIndex)
          let y2MaVals = vals.slice(dates.length-21, dates.length)
          let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))

          let scale = (reduction2/reduction1)-1
          //let scale = 0
          if(scale > 0) {
            scale = (Math.abs(scale) * 175) + 25 //255
            if(scale > 120) {
              scale = 120
            }
            return [139, 0, 0, scale]
          } else {
            scale = (Math.abs(scale) * 175) + 25
            if(scale > 120) {
              scale = 120
            }
            return [0, 0, 139, scale]
          }
        } else {
          return [0, 0, 0, 0]
        }
      }, 
      autoHighlight: true,
      getLineColor: [120,120,120,120],
      getRadius: 2,
      getLineWidth: 1,
      parameters: {
        depthTest: false,
      },
      updateTriggers: {
        getFillColor:  [d => {
          if(d.properties.no2Date != null) {
            let vals = d.properties.no2Scale.split(",")
            let dates = d.properties.no2Date.split(",")
            let result = Object.assign(...dates.map((k, i) => ({[k]: vals[i]})))
            let scale = result[this.props.date]
            return [139, 0, 0, scale * 250]
          } else {
            console.log("...")
            return [139, 0, 0, 0]
          }
        }],
        
      }
    });
    return layer
  }

  cityLayer = () => {
    if(this.props.election == true) {
      var data = 'https://s3.wasabisys.com/satellite.globalmonitor.ch/shp/l2/election.json'
    } else if(this.props.hoverId_l1 == 'Blank') {
      var data = 'https://s3.wasabisys.com/satellite.globalmonitor.ch/shp/l2/election.json'
    } else {
      //var data = 'http://tiles.emissions.ch/' + this.props.emissions + '/shapefiles/l2/'  + this.props.hoverId_l2 + '_' + this.props.abr + '.json'
      var data = './tiles/' + this.props.emissions + '/shapefiles/l2/'  + this.props.hoverId_l2 + '_' + this.props.abr + '.json'

    }

    const layer = new GeoJsonLayer({
      id: 'tiles-for-main-geojson-layer3',
      data,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false, 
      onClick: (info, event) => {
        console.log('cityView()')
        this.props.pushState(info, this.props.hoverId_l2, "cityLayer")
      },
      onHover: (d, event) => {
        if(d.object != undefined) {
          if(d.object.properties.voteAgg != null && this.props.election == true) {
            let percentAgg = d.object.properties.percentAgg.split(",").map(Number)
            let candidates = d.object.properties.candidates.split(",")
            let votesAgg = d.object.properties.voteAgg.split(",").map(Number)
            let donIndex = candidates.indexOf('Donald Trump')
            let joeIndex = candidates.indexOf('Joe Biden')
            let donPercent = percentAgg[donIndex]
            let joePercent =  percentAgg[joeIndex]
            let otherPercent = 100 - (donPercent + joePercent)
            let donVotes = votesAgg[donIndex]
            let joeVotes = votesAgg[joeIndex]
            let otherVotes = votesAgg.reduce((sum, x) => sum + x) - (donVotes + joeVotes)
            let vals = d.object.properties.no2YoY.split(",").map(Number)
            let lockdownAvg = vals.slice(60,152)
            vals = vals.filter( value => !Number.isNaN(value))
            lockdownAvg = lockdownAvg.filter( value => !Number.isNaN(value))
            let reduction = vals.reduce((a,v,i)=> (a*i+v)/(i+1))
            let lockdownReduction = lockdownAvg.reduce((a,v,i)=> (a*i+v)/(i+1))
            this.setState({hoverLevel: 'county', picked: true, yoyChange: reduction, lockdownChange: lockdownReduction, hover: event.srcEvent, state: d.object.properties.NAME_1, county: d.object.properties.NAME_2, donPercent: donPercent, donVotes: donVotes, joePercent: joePercent, joeVotes: joeVotes, otherPercent: otherPercent, otherVotes: otherVotes})
          } else {
            let vals = d.object.properties.no2Val.split(",").map(Number)
            let dates = d.object.properties.no2Date.split(",")
            let dateIndex = dates.indexOf(this.props.date)
            if(dateIndex > 365) {
              var startIndex = 365
            } else if(dateIndex = -1) {
              startIndex = dates.length - 365
              dateIndex = dates.length
            } else {
              startIndex = 22
            }
            console.log("Reducing...")
            let y1MaVals = vals.slice(startIndex-21, startIndex)
            let y2MaVals = vals.slice(dates.length-21, dates.length)
            let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
            let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
            let reduction = (reduction2/reduction1)-1
            /*let vals = d.object.properties.no2YoY.split(",").map(Number)
            vals = vals.filter( value => !Number.isNaN(value))
            let reduction = vals.reduce((a,v,i)=> (a*i+v)/(i+1))*/
            this.setState({hoverLevel: 'county', picked: true, yoyChange: reduction, hover: event.srcEvent, state: d.object.properties.NAME_1, county: d.object.properties.NAME_2})
          }
        } else if(d.picked == false) {
          this.setState({picked: false})
        }
      },
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      /*getFillColor: d => {
        if(d.properties.voteAgg != null && this.props.election == true) {
          let percentAgg = d.properties.percentAgg.split(",")
          let candidates = d.properties.candidates.split(",")
          let donIndex = candidates.indexOf('Donald Trump')


          let scale = parseFloat(percentAgg[donIndex])/100
          if(scale > .5) {
            return [250,0,0,139*scale]
          } else {
            return [0,0,250,139*scale]
          }
        } else if(d.properties.no2Date != null && this.props.election != true) {
          let vals = d.properties.no2Val.split(",").map(Number)

          //vals = vals.filter( value => !Number.isNaN(value) )
          let dates = d.properties.no2Date.split(",")
          //let vals = d.properties.no2Scale.split(",")
          /*let dates = d.properties.no2Date.split(",")
          //let result = Object.assign(...dates.map((k, i) => ({[k]: vals[i]})))
          //let scale = result[this.props.date]
          let vals = d.properties.no2YoY.split(",").map(Number)
          vals = vals.filter( value => !Number.isNaN(value) )

          //let scale = vals[vals.length - 1]
          let scale = vals.reduce((a,v,i)=> (a*i+v)/(i+1))
          if(scale > 0) {
            //scale = (scale*50)/40
            scale = (Math.abs(scale) * 255) + 25
            if(scale > 150) {
              scale = 150
            }
            let dateIndex = dates.indexOf(this.props.date)
            if(dateIndex > 365) {
              var startIndex = 365
            } else if(dateIndex = -1) {
              startIndex = dates.length - 365
              dateIndex = dates.length
            } else {
              startIndex = 22
            }
            let maVals = vals.slice(startIndex, dateIndex)
            let scale = maVals.reduce((a,v,i)=> (a*i+v)/(i+1))
            let y1MaVals = vals.slice(startIndex-21, startIndex)
            let y2MaVals = vals.slice(dates.length-21, dates.length)

            let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
            let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
            //let scale = (reduction2/reduction1)-1
            ////////console.log('Orig: ', scale)
            //////////console.log(vals)
            if(scale > 0) {
              //scale = (scale*50)/40
            scale = (Math.abs(scale) * 255) + 25
            if(scale > 75) {
              scale = 75
            }

            return [139, 0, 0, scale]
          } else {
            scale = (Math.abs(scale) * 255) + 25
            if(scale > 150) {
              scale = 150
            }
            return [0, 0, 139, scale]
          }
        } else {
          return [139, 0, 0, 0]
        }
      }, */
      getFillColor: d => {
        if(d.properties.no2Date != null) {
          let vals = d.properties.no2Val.split(",").map(Number)
          let dates = d.properties.no2Date.split(",")
          let dateIndex = dates.indexOf(this.props.date)
          if(dateIndex > 365) {
            var startIndex = 365
          } else if(dateIndex = -1) {
            startIndex = dates.length - 365
            dateIndex = dates.length
          } else {
            startIndex = 22
          }
          console.log("Reducing...")
          let y1MaVals = vals.slice(startIndex-21, startIndex)
          let y2MaVals = vals.slice(dates.length-21, dates.length)
          let reduction1 = y1MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))
          let reduction2 = y2MaVals.reduce((a,v,i)=> (a*i+v)/(i+1))

          let scale = (reduction2/reduction1)-1
          //let scale = 0
          if(scale > 0) {
            scale = (Math.abs(scale) * 175) + 25 //255
            if(scale > 120) {
              scale = 120
            }
            return [139, 0, 0, scale]
          } else {
            scale = (Math.abs(scale) * 175) + 25
            if(scale > 120) {
              scale = 120
            }
            return [0, 0, 139, scale]
          }
        } else {
          return [0, 0, 0, 0]
        }
      },
      autoHighlight: true,
      getLineColor: [120,120,120,120],
      getRadius: 1,
      getLineWidth: 1,
      parameters: {
        depthTest: false,
      },
      updateTriggers: {
        getFillColor:  [d => {
          ////////////////console.log(d)
          if(d.properties.no2Date != null) {
            let vals = d.properties.no2Scale.split(",")
            let dates = d.properties.no2Date.split(",")
            let result = Object.assign(...dates.map((k, i) => ({[k]: vals[i]})))
            let scale = result[this.props.date]
            return [139, 0, 0, scale * 250]
          } else {
            return [139, 0, 0, 0]
          }
        }]
      }
    });
    return layer
  }

  hexagonLayer = () => {
      let data = this.props.searchCoords
      //console.log(data)
      const colorRange = [
        /*[254, 237, 177],
        [254, 173, 84],
        [209, 55, 78]*/

        [82, 108, 132, 115],
        [170, 0, 0, 170],
        [190, 0, 0, 190],
        /*[85, 0, 0, 85],
        [127, 0, 0, 127],
        [170, 0, 0, 170],
        [190, 0, 0, 190],*/
        [255, 0, 0, 225]
      ]
      if(data.length > 0) {
        data = data.map(d => {
          d = d.split(',')
          d = [Number(d[1]), Number(d[0])]
          return d
        })
      }
      const layer = new HexagonLayer({
        id: 'tiles-for-main-hexagonLayer',
        colorRange,
        data,
        //elevationRange: [1, 30000],
        //elevationScale: data && data.length ? 50 : 50,     
        pickable: true,
        extruded: true,
        radius: 20000,
        upperPercentile: 100,
        coverage: 1,
        //opacity: 0,
        //elevationScale: 100,
        getPosition: d => d,
        
      });
    return layer
    
  }

  iconLayer = () => {
    let data = this.props.searchCoords
    const colorRange = [
      [254, 237, 177],
      [254, 173, 84],
      [209, 55, 78]
    ]
    if(data.length > 0) {
      data = data.map(d => {
        d = d.split(',')
        d = [Number(d[1]), Number(d[0])]
        return d
      })
    }
    const layer = new IconLayer({
      id: 'tiles-for-main-iconLayer',
      //colorRange,
      data,
      iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      iconMapping: ICON_MAPPING,
      getIcon: d => 'marker',
      sizeScale: 15,
      //getPosition: d,
      getSize: d => 2,
      getColor: d => [Math.sqrt(d.exits), 140, 0],

      //elevationRange: [1, 30000],
      //elevationScale: data && data.length ? 50 : 50,     
      //pickable: true,
      //extruded: true,
      //radius: 20000,
      //upperPercentile: 100,
      //coverage: 1,
      //opacity: 0,
      //elevationScale: 100,
      getPosition: d => d,
      
    });
  return layer
  
  }

  // OSM Tile Layer
  baseLayer = () => { 
    //let opacity;
    //let loadTrigger = 0

    //this.props.pollution == false ? opacity = .5 : opacity = .15
    const tile = new TileLayer({
        data: this.props.base,
        id:'tiles-for-main-baseTile',
        //onTileError: () => loadTrigger = 1,
        minZoom: 0,
        maxZoom: 19,
        opacity: 1,
        tileSize: 256,
        onDrag: (i, e) => console.log(i,e),
        renderSubLayers: props => {
          const {
            bbox: {west, south, east, north}
          } = props.tile;
    
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north]
          });
        }
      });
      return tile
  }

  // OSM Tile Layer
  miniBaseLayer = () => { 
    //let opacity;
    //let loadTrigger = 0

    //this.props.pollution == false ? opacity = .5 : opacity = .15
    const tile = new TileLayer({
        data: this.props.base,
        id:'tiles-for-minimap-baseTile',
        //onTileError: () => loadTrigger = 1,
        minZoom: 0,
        maxZoom: 19,
        opacity: 1,
        tileSize: 256,
        onDrag: (i, e) => console.log(i,e),
        renderSubLayers: props => {
          const {
            bbox: {west, south, east, north}
          } = props.tile;
    
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north]
          });
        }
      });
      return tile
  }
  
  // Pollution Later
  tileLayer = () => {
    let opacity;
    
    this.props.pollution == false ? opacity = .7 : opacity = .7

    const dataUrl = this.props.tile + this.props.date + '/{z}/{x}/{y}.png'
    console.log(dataUrl)
    console.log(this.props.tile)
    let loadTrigger = 0
    const tile = new TileLayer({
        //data: 'http://tiles.emissions.ch/' + this.props.emissions + '/' + this.props.date + '/{z}/{x}/{y}.png',
        data: './tiles/' + this.props.emissions + '/' + this.props.date + '/{z}/{x}/{y}.png',

        onTileError: () => loadTrigger = 1,
        id: 'tiles-for-minimap-pollutionTile',
        minZoom: 0,
        maxZoom: 8,
        opacity: opacity,
        maxRequests: 6,
        tileSize: 256,
        onDrag: (event) => console.log(event),
        //maxCacheSize: 0,
        renderSubLayers: (props) => {
          const {
            bbox: {west, south, east, north}
          } = props.tile;
          /*if(loadTrigger == 1) {
            props.data = []
            //////////////console.log(props.data)
            loadTrigger = 0
            //this.forceUpdate()  
          } //else {*/
            return [
              new BitmapLayer(props, opacity, {
                //id: 'pollutionTileBitmap',
                data: null,
                image: props.data,
                bounds: [west, south, east, north],
                //tintColor: 
                //transparentColor: [0,0,0,0],
                /*parameters: {
                  depthTest: false,
                }*/
              }) 
            ]
          //}
        }
          
      });
      return tile
  }

  // Pollution Later
  lightLayer = () => {
    let opacity = 1;
    let loadTrigger = 0
    const tile = new TileLayer({
        //data: 'https://satellite.globalmonitor.ch/tiles/viirs/test/03-2021/{z}/{x}/{y}.png',
        data: './tiles/nitrogendioxide/' + this.props.date + '/{z}/{x}/{y}.png',

        onTileError: () => loadTrigger = 1,
        id: 'tiles-for-minimap-lightTile',
        minZoom: 0,
        maxZoom: 8,
        opacity: opacity,
        maxRequests: 6,
        tileSize: 256,
        onDrag: (event) => console.log(event),
        renderSubLayers: (props) => {
          const {
            bbox: {west, south, east, north}
          } = props.tile;
            return [
              new BitmapLayer(props, opacity, {
                data: null,
                image: props.data,
                bounds: [west, south, east, north],
              }) 
            ]
        }
          
      });
      return tile
  }

  // ScreenGridLayer (Test)
  screenGridlayer = () => {
    let data = this.props.searchCoords

    if(data.length > 0) {
      data = data.map(d => {
        d = d.split(',')
        d = [Number(d[1]), Number(d[0])]
        return d
      })
    }
    const layer = new ScreenGridLayer({
      id: 'tiles-for-main-screenGridLayer',
      colorRange: [
        [25, 0, 0, 25],
        [85, 0, 0, 85],
        [127, 0, 0, 127],
        [170, 0, 0, 170],
        [190, 0, 0, 190],
        [255, 0, 0, 225]
      ],
      data,  
      pickable: false,
      opacity: 1,
      cellSizePixels: 25,
      getPosition: d => d,
      
    });
    return layer
  }

  contourLayer = () => {
    //var tData = JSON.parse(testData)
    var tempData = this.props.lines
    ////console.log(tData)
    if(tempData.length > 1) {
      var data = tempData.filter(d => d.level > .00002).map(d => {
        var level = d.level
        //console.log(level)
          var lat = d.x
          var lon = d.y
          var retArray = lat.map(function(latitude, index){
            return [lon[index], latitude]
          })
          return {'coordinates': retArray, 'levels': level}
      })
    } 
    //console.log(data)
    const layer = new PolygonLayer({
      id: 'tiles-for-main-geojson-layer',
      data,
      pickable: false,
      filled: true,
      stroked: true,
      wireframe: false,
      fp64: true,
      lineWidthMinPixels: 1,
      getLineColor: [80, 80, 80],
      getFillColor: d => [139, 0, 0, d.levels*225*100],
      //widthScale: 20,
      //widthMinPixels: 2,
      getPolygon: d => d.coordinates,
      //getWidth: d => 1
    });
    return layer
  }

  // ContourLayer (Test)
  /*contourLayer = () => {
    let coordData = this.props.searchCoords
   // //console.log(coordData)
    let data = []
    if(coordData.length > 0) {
      //data = data.slice(0,10)
      coordData.map(d => {
        d = d.split(',')
        d = [Math.round(Number(d[1])), Math.round(Number(d[0]))]
        if(isLongitude(d[0]) == true && isLatitude(d[1]) == true) {
          data.push(d)
        } else {
          //console.log('Bad')
        }
      })
    }
    if(data.length > 0) {
      var features = turf.featureCollection([
        data.map(d => turf.point(d))
      ])
      //console.log(data)
      //console.log(features)
      //console.log(turf.isobands(features, [.005, .1, 1]))
    }

    ////console.log(data)
    //data = [[-74.9384,42.1497],[-80.0726,42.1112],[-75.1638,39.9523],[-80.1937,25.7743],[-87.6501,41.85],[-75.1638,39.9523], [-74.9384,42.1497],[-80.0726,42.1112],[-75.1638,39.9523],[-80.1937,25.7743],[-87.6501,41.85],[-75.1638,39.9523]]
    const layer = new ContourLayer({
      id: 'tiles-for-main-contourLayer',
      //data:'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/screen-grid/ca-transit-stops.json',
      //data: JSON.parse(finData),
      //data: '/home/mike/test.json',
      data,
      //cellSize: 100,
      contours: CONTOURS,
      getPosition: d => {
        //console.log(d.Longitude);
        return [d.Longitude, d.Latitude];
      },
      
    });
    return layer
  }*/

  /*contourLayer = () => {
    //let coordData = this.props.searchCoords
    let coordData = JSON.parse(testData)
    //console.log(typeof coordData)
    let featureColl = []
    let data = []
    let hullData = []
    let dbData = []
    if(coordData.length > 0) {
      coordData.map(d => {
        //console.log(d)
        const cluster = Number(d.group) - 1
        d = [Number(d.Longitude), Number(d.Latitude)]
        //console.log(d)
        if(isNaN(cluster) == false) {
          if(isLongitude(d[0]) == true && isLatitude(d[1]) == true) {
            if(hullData[cluster] == undefined) {
              hullData[cluster] = []
            }
            hullData[cluster].push(d)
          } 
        } 
      })
      if(dbData.length == 0) {
        //console.log('hull')
        for(var i = 0; i < hullData.length; i = i+1) {
          data.push(hull(hullData[i], 10))
        }
      }
    } 

    const layer = new PolygonLayer({
      id: 'tiles-for-main-clusterLayer',
      data,
      getLineColor: [80, 80, 80],
      getFillColor: [80, 80, 80, 120],
      elevated: true,
      elevationScale: 1,
      getPolygon: d => {
        //console.log(d)
        return d
      },
      stroked: true,
      filled: true,
      wireframe: false,
      lineWidthMinPixels: 2,
      pickable: true,
    });
    const layer2 = new PolygonLayer({
      id: 'tiles-for-main-clusterLayer',
      data,
      getLineColor: [80, 80, 80],
      getFillColor: [80, 80, 80, 120],
      elevated: true,
      elevationScale: 1,
      getPolygon: d => {
        //console.log(d)
        return d
      },
      stroked: true,
      filled: true,
      wireframe: false,
      lineWidthMinPixels: 2,
      pickable: true,
    });
    return [layer, layer2]
  }*/

  clusterLayer = () => {
    let coordData = this.props.searchCoords
    let featureColl = []
    let data = []
    let hullData = []
    let dbData = []
    if(coordData.length > 0) {
      ////console.log(coordData)
      //data = data.slice(0,10)
      coordData.map(d => {
        d = d.split(',')
        const cluster = Number(d[2]) - 1
        d = [Number(d[1]), Number(d[0])]
        if(isNaN(cluster) == false) {
          if(isLongitude(d[0]) == true && isLatitude(d[1]) == true) {
            if(hullData[cluster] == undefined) {
            //if(featureColl[cluster] == undefined) {
              //featureColl[cluster] = []
              hullData[cluster] = []
            }
            hullData[cluster].push(d)
            //featureColl[cluster].push(turf.point(d))
          } else {
          }
        } else {
          //dbData.push(d)
        }
      })
      //console.log(hullData, dbData)
      if(dbData.length == 0) {
        //console.log('hull')
        for(var i = 0; i < hullData.length; i = i+1) {
          //const iCollection = turf.featureCollection(featureColl[i])
          //var options = {concavity: 10};
          //let turfData = turf.convex(iCollection, options);
          ////console.log(turfData)
          //var t2data = turf.polygonSmooth(turfData, {iterations: 1})
          ////console.log(t2data)
          //data.push(turfData.geometry.coordinates)
          //data.push(t2data.features[0].geometry.coordinates)
  
          data.push(hull(hullData[i], 10))
        }
      } else {
        //console.log('dbscan')
        var clusters = dbscan.run(dbData, 5, 10)
        //console.log(clusters)
        clusters = clusters.map(function(cluster) {
          return cluster.map(function(i) {
              return dbData[i]; // map index to point
          });
        });
        clusters.forEach(function(dbData) {
          var pts = hull(dbData, 30);
          data.push(pts)
          // ... draw pts
        })
      }
      
      //console.log(data)
    } else {
      //data.coordinates = []
    }

    //console.log(data)
    const layer = new PolygonLayer({
      id: 'tiles-for-main-clusterLayer',
      data,
      getLineColor: [80, 80, 80],
      getFillColor: [80, 80, 80, 120],
      elevated: true,
      elevationScale: 1,
      getPolygon: d => {
        //console.log(d)
        return d
      },
      stroked: true,
      filled: true,
      wireframe: false,
      lineWidthMinPixels: 2,
      pickable: true,
    });
    const layer2 = new PolygonLayer({
      id: 'tiles-for-main-clusterLayer',
      data,
      getLineColor: [80, 80, 80],
      getFillColor: [80, 80, 80, 120],
      elevated: true,
      elevationScale: 1,
      getPolygon: d => {
        //console.log(d)
        return d
      },
      stroked: true,
      filled: true,
      wireframe: false,
      lineWidthMinPixels: 2,
      pickable: true,
    });
    return [layer, layer2]
  }

  render() {
    ////////////console.log(devicePixelRatio)
    const postProcessEffect = new PostProcessEffect(fxaa);
    const postProcessEffect2 = new PostProcessEffect(phongLighting, {
      ambient: 0.01, diffuse: 0.01, shininess: 0.01, specularColor: [30, 0, 0]
    });

    const views = [
      new MapView({id: 'main', controller: true, repeat:true}),
      /*new MapView({id: 'minimap', x: this.state.screenX, y: this.state.screenY, width: '20%', 
        height: '20%', controller: true, 
        viewState: {
          // Share the view state id of the main map
        
          /*id: {  
            latitude: this.state.coordX,
            longitude: this.state.coordY
          },
          latitude: this.state.coordY,
          longitude: this.state.coordX,
          // view state overrides: stay zoomed out and looking straight down, north up
          zoom: 4,
          pitch: 0,
          bearing: 0
        }
      })*/
    ]

    const layers=[
      this.meshLayer(), 
      this.baseLayer(),
      this.clusterLayer(),
      this.miniBaseLayer(),
      //this.tileLayer(),

      this.props.tileToggle == true ? this.tileLayer() : null, 
      //this.lightLayer(),
      this.props.choropleth == true ? this.mapLayer() : null, 
      this.props.choropleth == true ? this.stateLayer() : null, 
      this.props.choropleth == true ? this.cityLayer() : null, 

      //this.pathLayer(), 
      //this.props.choropleth == true ? this.mapLayer() : null,
      //this.props.election == false ? this.mapLayer() : null,

      //this.props.election == false ? this.stateLayer() : null,
      
      //this.mapLayer(),
      //this.stateLayer(),
      //this.cityLayer(),
      //this.hexagonLayer(),
      this.screenGridlayer(),
      this.contourLayer(),
      //this.iconLayer(),

    ] 

    const layerFilter = ({layer, viewport}) => {
      ////console.log(layer, viewport)
      if(layer != undefined) {
        if(layer.id != undefined) {
          return layer.id.startsWith(`tiles-for-${viewport.id}`)
          return true
        }
      }
    }

    /*const layerFilter = ({layer, viewport}) => {
      //console.log(layer, viewport)

      const layer = canvas.layer
      const viewport = canvas.viewPort
      if(layer != undefined && viewport != undefined) {
        if (layer.id.startsWith('tiles-for')) {
          return layer.id.startsWith(`tiles-for-${viewport.id}`);
        }
      }
      return true
    }*/
    ////console.log(this.state)
    console.log('map.jl 1214 ')
    console.log(this.state)
    return(
        <DeckGL 
          initialViewState={this.props.viewState} 
          onDrag = {(event) => {
            //console.log(event)
            //console.log(event.coordinate)
            if(event.viewport != undefined) {
              this.setState({screenX: event.x, screenY: event.y, coordX: event.coordinate[0], coordY: event.coordinate[1]})
            }
          }}
          //views={new MapView({id: 'test', repeat: true})}
          //views = {new MapView({id: 'main', controller: true, repeat:true})}
          //views={views}
          layers = {layers}
          //layerFilter = {layerFilter}
          id="testMap"
          controller = {true}
        >
          {this.state.picked == true ? this.props.election == true ? <div style={{position: 'absolute', zIndex: 99999, width: 300, left: this.state.hover.x - 14, top: this.state.hover.y + 15}} >
                  <Popover id="popover-positioned-top" placement="bottom">
                   <Popover.Title as="h3"><div>{this.state.county} County</div>{this.state.state == 'Alaska' ? <div> (Statewide Election Results)</div> : null}</Popover.Title>
                    <Popover.Content>                    
                     <FontAwesomeIcon icon={faRepublican} color='red' /> Donald Trump: {this.state.donPercent.toFixed(2)}% ({this.state.donVotes}) <br />
                     <FontAwesomeIcon icon={faDemocrat} color='blue' /> Joe Biden: {this.state.joePercent.toFixed(2)}% ({this.state.joeVotes}) <br />
                     <FontAwesomeIcon icon={faQuestionCircle} /> Other: {this.state.otherPercent.toFixed(2)}% ({this.state.otherVotes}) <br />
                     <hr />
                     <FontAwesomeIcon icon={faIndustry} color={this.state.yoyChange > 0 ? 'red' : 'black'} /> {(this.state.yoyChange*100).toFixed(2)}% Annual Emissions {this.state.yoyChange > 0 ? 'Increase' : 'Decline'}* <br />
                     <hr />
                     *Annual {this.state.yoyChange > 0 ? 'increase' : 'decrease'} in emissions. Click on <Button size="sm"><FontAwesomeIcon icon={faInfo} /></Button> for more information.
                    </Popover.Content>
                  </Popover>
          </div> :
          <div style={{position: 'absolute', zIndex: 99999, width: 300, left: this.state.hover.x - 14, top: this.state.hover.y + 15}} >
            <Popover id="popover-positioned-top" placement="bottom">
            <Popover.Title as="h3"><div>{this.state.hoverLevel == 'county' ? this.state.county : this.state.state}, {this.state.hoverLevel == 'county' ? this.state.state : this.state.country}</div></Popover.Title>
              <Popover.Content>                    
              <FontAwesomeIcon icon={faIndustry} color={this.state.yoyChange > 0 ? 'red' : 'black'} /> {(this.state.yoyChange*100).toFixed(2)}% Annual Emissions {this.state.yoyChange > 0 ? 'Increase' : 'Decline'}* <br />
              <hr />
              *Annual {this.state.yoyChange > 0 ? 'increase' : 'decrease'} in emissions based on a 21 day moving average. Click on <Button size="sm"><FontAwesomeIcon icon={faInfo} /></Button> for more information.
              </Popover.Content>
            </Popover>
          </div> : null}
        </DeckGL>
    )
  }
}
